import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ListServices, ListVisibleServices } from "../../../axios/api";


function ServiceSection() {
  const [show, setshow] = useState('');
  const [services, setservices] = useState([]);
  const [array, setarray] = useState([]);
  const [state, setstate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(ListVisibleServices)
    .then((response)=>{
      const { data , StatusCode} = response.data.app_data;

      if(StatusCode === 6000){
        setservices(data)
        setstate(!state)
      }else{
          setservices([])
      }
    })
  },[])
  
  useEffect(() => {
    const tempArray = [];
    let currentAdded = 0;
    for (let i = 0; i < services?.length; i++) {
        
        const Pattern1 = ['Gr','Wh']
        const Pattern2 = ['Wh','Gr']
        
        if(i % 2 === 0){
          if(currentAdded === 1){
            tempArray.push(...Pattern2)
            currentAdded = 2
          }else{
            tempArray.push(...Pattern1)
            currentAdded = 1
          }
        }
    }
    setarray(tempArray)

  },[state])

  const handleViewMore = (index) => {
      setshow(index)
  };

  const handleViewLess = () => {
    setshow('')
  };

  return (
    <Section>
      <BreadcrumbArea>
          <BTitle>
            Services /
          </BTitle>
          <Path>
            Home/Services
          </Path>
      </BreadcrumbArea>
      <Wrapper className="wrapper">
        <Container>
          <BoxContainer>
            <Box>
              <Title>
                OUR SERVICES
              </Title>
            </Box>
            <Box>
              <Para>
              We bring your dreams to life, turning every space into a harmonious blend of beauty and functionality. Our comprehensive range of interior design services encompasses every corner of your home or workspace including Living Room Interior, Kitchen Interior, Bedroom Interior, Villa Interior, Flat Interior and Office Interior. Elevate your living experience with our expertise in crafting personalized Interior Design Services.
              </Para>
            </Box>
          </BoxContainer>
          <BoxContainer className="service-container">
            {services.map((obj, index) =>{
              return(
                <Box className="card">
                  <Img className={array[index] === "Gr" && "cl-grey"}>
                    <img  src={obj?.image} alt="" />
                  </Img>
                  <Div className={array[index] === "Gr" && "cl-grey"}>
                    <BoxTitle>{obj?.name}</BoxTitle>
                    {obj?.details.length > 450 ?(
                    <BoxDescContainer>
                       <BoxDesc>
                          { show === index ? obj.details : obj.details.slice(0,450)}
                       </BoxDesc>
                       {
                        show === index ? (
                        <ViewLess onClick={()=>handleViewLess()}>
                        Less
                        </ViewLess>
                        ): (
                        <ViewMore onClick={()=>handleViewMore(index)}>
                        View more
                       </ViewMore>
                        )
                       }
                      </BoxDescContainer>
                    ):(
                      <BoxDesc>
                        {obj?.details}
                      </BoxDesc>
                    ) }
                    <Btn>
                        <LearnMore onClick={()=>navigate(`/service/${obj?.slug}`)}>
                        Learn More
                        </LearnMore>
                    </Btn>
                  </Div>
                </Box>
              );
            })}
          </BoxContainer>
        </Container>
      </Wrapper>
    </Section>
  );
}

export default ServiceSection;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const BreadcrumbArea = styled.div`
    display: flex;
    justify-content:space-between;
`;

const BTitle = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Path = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Wrapper = styled.div`
  margin-bottom: 80px;

  @media(max-width:580px){
    width:100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media(max-width:900px){
    row-gap: 50px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 130px;
  column-gap: 40px;

  &.service-container{
    column-gap: 60px;
  }
  
  @media(min-width:2200px){
    column-gap: 130px !important;
  }

  
  @media(max-width:1200px){
    gap: 70px !important;
  }
  
  @media(max-width:900px){
    row-gap: 64px;
  }

  @media(max-width:580px){
    width:80%;
    margin: 0 auto;
    gap:0 !important;

    &.service-container{
    width:100%;
    gap:50px !important;
    }
  }

`;

const Box = styled.div`
  width:45%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;

  &.card {
    gap: 0;
  }
  @media(max-width:1200px){
    width:100%;
  }
  @media(min-width:2200px){
      width:45%;
  }

`;

const Title = styled.div`
    padding: 0;
    margin: 0;
    font-size: 50px;
    font-family: 'wensley_bold' !important;
    color: var(--secondary-cl);
    margin-bottom: 25px;

  @media(max-width:1350px){
    font-size: 60px;
  }

  @media(max-width:580px){
    font-size: 35px;
  }
`;

const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color: #eb6502;
`;

const SubTitle = styled.div`
  color: #606060;

  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
`;

const Classic = styled.div`
  color: #606060;
  font-size: 65px;
  
  @media(max-width:1350px){
    font-size: 60px;
  }

  @media(max-width:580px){
    font-size: 50px;
  }

  @media(max-width:480px){
    font-size: 45px;
  }

  @media(max-width:400px){
    font-size: 40px;
  }
  @media(max-width:350px){
    font-size: 30px;
  }
`;

const Para = styled.div`
    color: #706e6c;
    padding: 0;
    margin: 0; 
    line-height: var(--line-height);
    font-family: var(--ff);
    font-size: var(--para-fs);
    margin-bottom: 20px;

  @media(max-width:900px){
    text-align: justify;
  }
`;

const Img = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 170px;

  img {
    margin: 0 30px;
    z-index: 1;
    height: 170px;
    width: auto;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    background-color: #ffff;
  }

  &.cl-grey {
      &::after{
        background-color: #f4f4f4;
      }
    }

  @media(max-width:400px){
     img{
      margin:0 auto;
      width:100%;
     }
  }

    
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  background: #ffff;

  &.cl-grey {
    background-color: #f4f4f4;
  }

 @media(max-width:400px){
     padding: 25px;
     box-sizing: border-box;
  }
  @media(max-width:375px){
     padding: 20px;
     box-sizing: border-box;
  }
`;

const BoxTitle = styled.div`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.38px;
  min-height: 30px;
`;

const BoxDescContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap:5px;
`;

const BoxDesc = styled.div`
    color: #000;
    text-align: justify;
    font-family: 'raleway_regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%; /* 28px */
`;

const Btn = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  @media(max-width:500px){
    flex-direction:column;
  }
`;

const MailBtn = styled.a`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background-color: #eb6502;
  border: none;
  outline: none;
  width: 150px;

  @media(max-width:500px){
    width: 80%;
    margin: 0 auto;
  }
`;

const LearnMore  = styled.button`
        color: #FFF;
        font-family: 'raleway_semibold';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.04px;
        border: none;
        background: #EB6502;
        padding: 15px 20px;
        outline:none;
        cursor: pointer;
`;

const Span = styled.a`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.mail {
    color: #ffff;
  }
`;

const Icon = styled.div`
  img {
    width: 16px;
    color: #706e6c;
    height: 16px;
  }
`;

const ViewMore = styled.div`
    color: #EB6502;
    text-align: justify;
    font-family: 'railway-regular';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 33px */
    text-align: end;
    cursor: pointer;
`;

const ViewLess = styled.div`
    color: #EB6502;
    text-align: justify;
    font-family: 'raleway_regular';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 33px */
    text-align: end;
    cursor: pointer;
`;