import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../src/assets/css/style.css';
import App from './App';
import { BrowserRouter as Router, ScrollRestoration} from 'react-router-dom';
import StoreContext from './context/StoreContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
      <StoreContext>
        <App />
      </StoreContext>
    </Router>
);

