import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ListProjectsApi } from '../../../axios/api';


function ProjectsList() {

  const [projects, setprojects] = useState({});
  const [projectArray, setprojectArray] = useState([]);
  const [state, setstate] = useState(false);
  const [page, setpage] = useState(1);
  const navigate = useNavigate();

  const handleClick = (slug)=>{
    navigate('/project/'+ slug);
  }
  
  // Fetch projects 
  useEffect(() => {
    axios.get(ListProjectsApi,{
      params :{
        'page': page
      }
    }).then((response)=>{
        const { data , StatusCode } = response.data.app_data;

        if(StatusCode === 6000){
          setprojects(data)
          setstate(!state)
        }else{
          setprojects([data])
        }
    })
  },[page])

  useEffect(() => {
    let currentList =projects.results;
    
    const pattern = [40,24,34,28,32,38,34,40,24]
    const Mobilepattern = [60,39,39,60]
    let counter = 2;
    let current = 0;
    let mobileCurrent = 0;
    const newMyArray = currentList?.map((obj)=>{
          let temp={
            ...obj,
            width:pattern[current],
            smWidth:Mobilepattern[mobileCurrent],
            id:counter
          }
          current = current + 1 === pattern.length ? 0 : current + 1;
          mobileCurrent = mobileCurrent + 1 === Mobilepattern.length ? 0 : mobileCurrent + 1;
          counter++;

          return temp;
    })

    setprojectArray(newMyArray);
  },[state])
  
  const handleLoadMore = () => {
      if(page <= parseInt(projects?.num_pages)){
        setpage((prev)=>prev + 1);
      }
  };

  return (
    <Section>
      <ProjectListArea>
        {projectArray?.map((obj,indx)=>{
            
            if(indx % 2 === 0){

                return(
                    <SingleProject w={obj.width} sm={obj.smWidth} onClick={()=>handleClick(obj?.slug)} >
                      <ImageContainer>
                        <img loading='lazy'  src={obj?.project_images[0].image} alt="" />
                      </ImageContainer>
                      <Name>
                        <p>{obj?.title}</p>
                      </Name>
                    </SingleProject>
                )

            }else{

                return(
                    <SingleProject w={obj.width} sm={obj.smWidth} onClick={()=>handleClick(obj?.slug)}>
                      <ImageContainer>
                        <img loading='lazy'  src={obj?.project_images[0].image} alt="" />
                      </ImageContainer>
                      <Name>
                      <p>{obj?.title}</p>
                      </Name>
                    </SingleProject>
                )

            }
            
        })}
      </ProjectListArea>
      {
        page < projects?.num_pages &&
        <LoadButton>
        <button onClick={()=>handleLoadMore()}>
           Load More
        </button>
        </LoadButton>
      }
     
    </Section>
  )
}

export default ProjectsList;

const Section = styled.div`
  @media(max-width:580px){
    width:100vw;
    margin-left:-5.5%;
  }
`;

const ProjectListArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* grid-template-columns: 40% 60%; */
  row-gap: 15px;
  margin:20px 0;

  @media(max-width:580px){
  row-gap: 5px;
  }
`;

const LoadButton = styled.div`
width: 100%;
margin: 0px 0px 25px;
display: flex;
justify-content: center;
align-items:center;

@media(max-width:1400px){
    margin: 40px 0px 25px;
    
}

button{
    border:1px solid var(--primary-cl);
    background-color: white;
    padding: 12px  26px;
    font-size:14px;
    font-family: 'raleway_medium';
    color: #000;

    &:hover{
        background-color: var(--primary-cl);
        color: #fff;
    }
}
`;

const SingleProject = styled.div`
cursor: pointer;
${(props)=>props.w && `width:${props.w}%;`}

@media(max-width: 1024px){
  ${(props)=>props.sm && `width:${props.sm}%;`}
}

@media(max-width: 900px){
  width: 49%;
}
@media(max-width:580px){
    width:100%;
    position: relative;
    &::after{
      content:'';
      position: absolute;
      inset: 0;
      background-color:#000;
      opacity: .3;
    }
}

@media(max-width:480px){
  width: 100%;
}

`;

const ImageContainer = styled.div`
width: 100%;
position: relative;
height: 90%;
max-height: 464px;
@media(max-width:580px){
  height: 100%;

}
    &:after{
        content: '';
        width: 0%;
        height: 100%;
        background-color: #c6c1c1;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 25%;
        transition: all .2s ease;
    }
    &:hover{
        &:after{
            width: 100%;
            transition: all 1.1s ease;
        }
    }
img{
    width: 100%;
    height: 100%;
    display: block;
}
`;

const Name = styled.div`
@media(max-width:580px){
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 25;
}
p{
margin-top: 12px;
font-family: 'raleway_medium';
margin-bottom:16px;

@media(max-width:1400px){
    font-size:14px;
}

@media(max-width:580px){
    color: #fff;
    font-size:21px;
    text-align:center;
}

@media(max-width:480px){
    margin-top: 8px;
}
@media(max-width:360px){
    width:17ch;
    font-size:19px;
  }

}
`;

