import React from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

function Breadecrumb() {
  const { slug } = useParams();
  return (
    <BreadcrumbArea>
          <Title>
            Blogs /
          </Title>
          <Path>
            Home/{slug.length > 40 ? slug.slice(0,40) + '...' : slug}
          </Path>
    </BreadcrumbArea>
  )
}

export default Breadecrumb;


const BreadcrumbArea = styled.div`
    display: flex;
    justify-content:space-between;
    margin: 25px 0px;
`;

const Title = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Path = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;