import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components';


function CustomImageSlider({imgs}) {
    const [sliderImgs, setsliderImgs] = useState([]);
    const [widthData, setwidthData] = useState([57,28.5,14.25]);
    const [hoverStatus, sethoverStatus] = useState(false);

    useEffect(() => {
        setsliderImgs(imgs)
    }, [imgs.length]);

    const handleMouseOverOnImg = (index)=>{
      if(index === 0){
        const widtharray = [];
        widtharray[0]=57
        widtharray[1]=28.5
        widtharray[2]=14.25
        setwidthData(widtharray);
      }else if(index === 1){
        const widtharray = [];
        widtharray[0]=28.5
        widtharray[1]=57
        widtharray[2]=14.25
        setwidthData(widtharray);
      }else{
        const widtharray = [];
        widtharray[0]=14.25
        widtharray[1]=28.5
        widtharray[2]=57
        setwidthData(widtharray);
      }
    };

    const handleMouseOverOnContainer = ()=>{
        sethoverStatus(true)
    };

    const handleMouseLeaveOnContainer = ()=>{
        setwidthData([57,28.5,14.25])
        sethoverStatus(false);
    };

  return (
    <Container onMouseOver={handleMouseOverOnContainer} onMouseLeave={handleMouseLeaveOnContainer}>

          <ImageSlider1 class="img-slider-1"  hoverState={hoverStatus} width={widthData[0]} >
              <img loading='lazy'  src={sliderImgs[0]} alt="" onMouseOver={()=>handleMouseOverOnImg(0)} />
          </ImageSlider1>

          <ImageSlider2  class="img-slider-2" hoverState={hoverStatus} width={widthData[1]}>
              <img loading='lazy'  src={sliderImgs[1]} alt="" onMouseOver={()=>handleMouseOverOnImg(1)} />
          </ImageSlider2>

          <ImageSlider3  class="img-slider-3" hoverState={hoverStatus} width={widthData[2]}>
                <img loading='lazy'  src={sliderImgs[2]} alt="" onMouseOver={()=>handleMouseOverOnImg(2)} />
          </ImageSlider3>
    </Container>
  )
}

export default CustomImageSlider;

const Container = styled.div`
display: flex;
width: 100%;
`;

const ImageSlider1 = styled.div`
      /* height:480px; */
      height:100%;
      overflow: hidden;
      margin:0px 2px;
      transition:width 1s ease-out;
      ${(props)=>props.hoverState==="false"?`width:57%`:`width:${props.width}%`};
      /* ${(props)=>props.bg&&`background-image:url(${props.bg})`}; */
      background-repeat:no-repeat;
      background-size:cover;
      @media(max-width:676px){
      height:520px;
      }
      img{
        height:100%;
        display: flex;
      }
`;
const ImageSlider2 = styled.div`
      ${(props)=>props.hoverState==="false"?`width:28.5%`:`width:${props.width}%`};
      /* height:480px; */
      height:100%;
      overflow: hidden;
      margin:0px 2px;
      transition:width 1s ease-out;
      /* ${(props)=>props.bg&&`background-image:url(${props.bg})`}; */
      background-repeat:no-repeat;
      background-size:cover;
      @media(max-width:676px){
      height:520px;
      }
      img{
        height:100%;
        display: flex;
      }
`;
const ImageSlider3 = styled.div`
      ${(props)=>props.hoverState==="false"?`width:14.27%`:`width:${props.width}%`};
      /* height:480px; */
      height:100%;
      overflow: hidden;
      margin:0px 2px;
      transition:width 1s ease-out;
      /* ${(props)=>props.bg&&`background-image:url(${props.bg})`}; */
      background-repeat:no-repeat;
      background-size:cover;
      @media(max-width:676px){
      height:520px;
      }
      img{
        height:100%;
        display: flex;
      }
`;


// const Container = styled.div`
// display: flex;
// width: 100%;
// position: relative;
// `;

// const ImageSlider1 = styled.div`
//       height:480px;
//       overflow: hidden;
//       margin:0px 2px;
//       transition:width 1s ease-out;
//       ${(props)=>props.hoverState==="false"?`width:100%`:`width:${props.width}%`};
//       ${(props)=>props.bg&&`background-image:url(${props.bg})`};
//       background-repeat:no-repeat;
//       background-size:cover;
//       z-index:100;
//       position:absolute;
//       img{
//         height:100%;
//         display: flex;
//       }
// `;
// const ImageSlider2 = styled.div`
//       ${(props)=>props.hoverState==="false"?`width:100%`:`width:${props.width}%`};
//       height:480px;
//       overflow: hidden;
//       margin:0px 2px;
//       transition:width 1s ease-out;
//       ${(props)=>props.bg&&`background-image:url(${props.bg})`};
//       background-repeat:no-repeat;
//       background-size:cover;
//       z-index:110;
//       position:absolute;
//       img{
//         height:100%;
//         display: flex;
//       }
// `;
// const ImageSlider3 = styled.div`
//       ${(props)=>props.hoverState==="false"?`width:100%`:`width:${props.width}%`};
//       height:480px;
//       overflow: hidden;
//       margin:0px 2px;
//       transition:width 1s ease-out;
//       ${(props)=>props.bg&&`background-image:url(${props.bg})`};
//       background-repeat:no-repeat;
//       background-size:cover;
//       z-index:120;
//       position:absolute;
//       img{
//         height:100%;
//         display: flex;
//       }
// `;



