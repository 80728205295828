import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Slider from "react-slick";
import axios from "axios";
import ReactPlayer from "react-player";

import playBtn from "../../../assets/images/icons/Playbtn.svg";
import TestimonialModal from "../../modal/TestimonialModal";
import { ListTestimonialApi } from "../../../axios/api";

function TestimonialSection() {
  const [modal, setmodal] = useState(false);
  const [video, setvideo] = useState(null);
  const [testimonials, settestimonials] = useState([]);

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 3000,
    prevArrow: (
      <PreviousButton>
        <button>&lt;&lt;</button>
      </PreviousButton>
    ),
    nextArrow: (
      <NextButton>
        <button>&gt;&gt;</button>
      </NextButton>
    ),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    axios.get(ListTestimonialApi).then((response) => {
      const { StatusCode, data } = response.data.app_data;

      if (StatusCode === 6000) {
        settestimonials(data);
      }
    });
  }, []);

  return (
    <Section>
      <Breadcrumb>Testimonials /</Breadcrumb>
      <Slider {...settings}>
        {testimonials.map((obj, index) => {
          if (obj.type === "image") {
            return (
              <TestimonialItemText>
                <Picture>
                  <ImgDiv>
                    <img loading="lazy" src={obj.image} alt="" />
                  </ImgDiv>
                </Picture>
                <Content>
                  <Name>{obj.name}</Name>
                  <Title>{obj.title}</Title>
                  <Separator id="separator" />
                  <Description>{obj.content}</Description>
                </Content>
              </TestimonialItemText>
            );
          } else {
            return (
              <TestimonialItemVideo>
                <Video>
                  <VideoDiv
                    onClick={() => {
                      setvideo({
                        video: obj.type === "video" ? obj.video : obj.url_field,
                        Name: obj.name,
                      });

                      setmodal(true);
                    }}
                  >
                    <ReactPlayer
                      width={"100%"}
                      height={"100%"}
                      url={obj.type === "video" ? obj.video : obj.url_field}
                      playing={true}
                      playsinline
                      muted
                      controls
                      loop
                    />
                  </VideoDiv>
                </Video>
                <VideoContent>
                  <Name id="name">{obj.name}</Name>
                  <Title id="title">{obj.title}</Title>
                  <Separator id="separator" />
                  <Description>{"“" + obj.content + "”"}</Description>
                </VideoContent>
              </TestimonialItemVideo>
            );
          }
        })}
      </Slider>

      {/* {testimonials?.length > 2 && (
        <ButtonContainer className="test">
          <PreviousButton>
            <button>&lt;&lt;</button>
            <p>PRE</p>
          </PreviousButton>
          <NextButton>
            <p>NEXT</p>
            <button>&gt;&gt;</button>
          </NextButton>
        </ButtonContainer>
      )} */}

      <TestimonialModal isModal={modal} setModal={setmodal} video={video} />
    </Section>
  );
}

export default TestimonialSection;

const Section = styled.div`
  padding: 20px;
  width: 85%;
  margin: 30px auto;
  position: relative;
  @media (max-width: 1275px) {
    width: 90%;
  }
  @media (max-width: 968px) {
    width: 95.7%;
  }
  @media (max-width: 768px) {
    width: 94.7%;
  }
  @media (max-width: 580px) {
    padding: 8px;
  }
  .slick-prev {
    position: absolute;
    bottom: 0;
    top: 105%;
    width: 31px;
    height: 30px;
    left: 0;
    opacity: 1;

    @media (max-width: 680px) {
      top: 100%;
      width: 45px;
      height: 45px;
    }
  }
  .slick-next {
    position: absolute;
    bottom: 0;
    top: 105%;
    right: 0;
    width: 31px;
    height: 30px;
    opacity: 1;

    @media (max-width: 600px) {
      top: 100%;
      width: 45px;
      height: 45px;
    }
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: start;
  justify-content: baseline;
  padding: 0px 10px;
  margin: 30px 0px;
  width: 75%;
  font-family: "raleway_light";
  font-size: 15px;
  @media (max-width: 968px) {
    padding: 0px 40px;
  }
`;

const TestimonialItemText = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 90% !important;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  height: 185px;
  cursor: pointer;
  @media (max-width: 968px) {
    padding: 0px;
  }
  @media (max-width: 580px) {
    flex-direction: column;
    height: auto;
  }
  &::after {
    content: "";
    height: 100%;
    width: 18%;
    background-color: var(--grey-white-cl);
    top: 0;
    left: 0;
    position: absolute;
    @media (max-width: 1024px) {
      width: 28%;
    }
    @media (max-width: 580px) {
      height: 120px;
      width: 100%;
      top: 55px;
    }
  }
`;

const TestimonialItemVideo = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 90% !important;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  height: 185px;
  cursor: pointer;
  @media (max-width: 968px) {
    padding: 0px;
  }
  @media (max-width: 580px) {
    flex-direction: column;
    height: auto;
  }
  &::after {
    content: "";
    height: 100%;
    width: 30%;
    background-color: var(--grey-white-cl);
    top: 0;
    left: 0;
    position: absolute;
    @media (max-width: 1024px) {
      width: 28%;
    }
    @media (max-width: 580px) {
      height: 120px;
      width: 100%;
      top: 55px;
    }
  }
`;

const IconImg = styled.div`
  @media (max-width: 330px) {
    width: 85%;
  }
`;

const Picture = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 15;
  flex: 1;

  @media (max-width: 580px) {
    height: 225px;
    align-items: center;
    justify-content: center;
  }
`;

const Video = styled.div`
  display: flex;
  width: 60%;
  z-index: 15;
  height: 100%;
  @media (min-width: 1900px) {
    width: 25%;
  }
  @media (max-width: 580px) {
    height: 225px;
    align-items: center;
    justify-content: center;
  }
`;

const Info = styled.div`
  width: 30%;
  display: none;
  position: relative;
  @media (max-width: 580px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:after {
      content: "";
      position: absolute;
      width: 200%;
      height: 60%;
      top: 30%;
      right: 0;
      background-color: var(--primary-cl);
    }
  }
`;

const ImgDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  @media (max-width: 580px) {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 175px;
    }
  }
`;

const VideoDiv = styled.div`
  height: 185px;
  width: 100%;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: right;
    background-color: #c9c9c9;
  }

  @media (max-width: 580px) {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 275px !important;
    }
    video {
      width: 275px !important;
      height: 150px !important;
    }
  }

  @media (max-width: 350px) {
    video {
      width: 250px;
      height: 128px;
    }
  }

  &::after {
    position: absolute;
    top: 38%;
    left: 40%;
    content: url(${playBtn});
    width: 50px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
  padding-left: 30px;
  flex: 2;
  @media (max-width: 580px) {
    #separator {
      display: none;
    }
    padding: 0;
  }
`;

const VideoContent = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 2px 0px;
  padding-left: 15px;
  @media (max-width: 580px) {
    #separator {
      display: none;
    }
    padding: 0;
    width: 100%;
  }
`;

const Name = styled.h4`
  font-family: "wensley_regular";
  margin: 5px 0px;
  font-size: 22px;
  @media (max-width: 580px) {
    text-align: center;
  }
`;

const Title = styled.p`
  font-family: "raleway_light";
  font-size: 15px;
  margin: 0px;
  margin-bottom: 15px;
  @media (max-width: 580px) {
    text-align: center;
    margin-bottom: 8px;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 3px 0px;
`;

const Description = styled.p`
  font-family: "raleway_regular";
  font-size: 14px;
  line-height: 22px;
  height: 90px;
  overflow-y: scroll;
  text-align: justify;

  &::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b4b4b4;
    opacity: 0.7;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
`;

const PreviousButton = styled.div`
  color: #827f7f;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  button {
    padding: 7px;
    background-color: #fff;
    border: 0.5px solid var(--primary-cl);
    color: #948f8f;
  }
  p {
    padding: 0px 4px;
    font-size: 15px;
    font-family: "raleway_regular";
  }
`;

const NextButton = styled.div`
  color: #827f7f;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  button {
    padding: 7px;
    background-color: #fff;
    border: 0.5px solid var(--primary-cl);
    color: #948f8f;
  }
  p {
    padding: 0px 4px;
    font-size: 15px;
    font-family: "raleway_regular";
  }
`;
