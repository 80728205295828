import React, { useEffect, useState } from "react";
import Header from "../components/includes/Header";
import axios from 'axios';
import Banner from "../components/pages/home/Banner";
import AboutSection from "../components/pages/home/AboutSection";
import FactoryInfo from "../components/pages/home/FactoryInfo";
import TestimonialSection from "../components/pages/home/TestimonialSection";
import ContactSection from "../components/pages/home/ContactSection";
import Footer from "../components/includes/Footer";
import CustomProjectSlider from "../components/sliders/CustomProjectSlider";
import styled from "@emotion/styled";
import Helmet from "../components/utils/Helmet";
import SeoArea from "../components/pages/home/SeoArea";
import { ListSingleSeoData } from "../axios/api";
import ContactUsModal from "../components/modal/ContactUsModal";

function Home() {
  const [seoData, setseoData] = useState({});
  const [modal,setmodal] = useState(false)
  const path = window.location.pathname

  useEffect(() => {
    const timer = setTimeout(() => {
      setmodal(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  
  // get seo Data
  useEffect(() => {
    axios.get(ListSingleSeoData,{
      params:{
        links:path
      }
    }).then((response) => {
      const { data, StatusCode } = response.data.app_data;

      if (StatusCode === 6000) {
        setseoData(data[0]);
      } else {
        setseoData({});
      }
    });
  }, []);

  return (
    <>
      <Helmet
        titleData={seoData?.meta_title}
        descriptionData={seoData?.meta_description}
      />
      <Header />
      <Banner />
      <AboutSection />
      <CustomProjectSlider />
      <FactoryInfo />
      <TestimonialSection />
      <SeoArea />
      <ContactSection />
      <Footer />
      <ContactUsModal isModal={modal} setModal={setmodal} />

    </>
  );
}

export default Home;

const Section = styled.div`
  width: 100vw;
  overflow-x: hidden;
  touch-action: none !important;
  -ms-touch-action: none !important;
`;
