import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import close from '../../assets/images/icons/close.svg';

import certificate from '../../assets/images/other/insides-certificate-pdf.webp';


function CertificateModal({ isModal, setModal, video }) {
  useEffect(() => {
     if(isModal){
        document.querySelector("html").classList.add("model-enabled");
     }else{
        document.querySelector("html").classList.remove("model-enabled");
     }
  }, [isModal])
  
  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <CloseIcon src={close} onClick={() => setModal(false)}/>
        <Img  src={certificate}/>
      </Modal>
    </Container>
  );
}
export default CertificateModal;

const Container = styled.div`
  position: fixed;
  /* transition: 0.1s; */
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: auto;
  height: auto;
  max-width: 1440px;
  max-height: 80vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background:var(--lightgrey);
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 4px 2px 40px #000;;
  background-color:transparent;

  &::-webkit-scrollbar {
    display: none;
  }
  /* @media all and (max-width: 1380px) {
    left: 58%;
  } */
  /* @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  } */
  @media all and (max-width: 580px) {
    width: 90%;
  }
`;

const Containers = styled.div`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap:15px;

  @media all and (max-width: 1300px) {
    padding: 30px;
  }

  @media all and (max-width: 580px) {
    padding: 15px;
  }

`;

const Img = styled.img`
      width: auto;
      /* aspect-ratio: 16/9; */
      height: 80vh;
      display: flex;
      object-fit:contain;
      @media all and (max-width: 580px) {
        width: 100%;
      }
      @media all and (max-width: 400px) {
        width: 100%;
        height: auto;
      }
`;


const CloseIcon = styled.img`
      width:20px;
      position: absolute;
      right:5px;
      top:5px;
      border: 1px solid black;
      border-radius: 50%;
`


