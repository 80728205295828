import './App.css';
import './assets/js/main.js';
import {Routes,Route} from 'react-router-dom';
import Home from './pages/Home';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'video-react/dist/video-react.css';
import Factory from './pages/Factory';
import Projects from './pages/Projects';
import ProjectInner from './pages/ProjectInner';
import AboutPage from './pages/AboutPage';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import BlogInner from './pages/BlogInner';
import Loader from './components/utils/Loader';
import { useState } from 'react';
import { useEffect } from 'react';
import ErrorPage from './pages/ErrorPage.jsx';
import ScrollTop from './components/utils/ScrollTop.jsx';
import Service from './pages/Service.jsx';
import ServiceInner from './pages/ServiceInner.jsx';
import ThankYou from './pages/ThankYou.jsx';
import TermsAndConditions from './pages/TermsAndConditions.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';


function App() {
  const [loader, setloader] = useState(true);
  
  useEffect(() => {
    setTimeout(()=>{
      setloader(false);
    },2500)
  }, [])
  
  return (
    <>
    <ScrollTop/>
     <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/factory-info' element={<Factory/>} />
      <Route path='/projects' element={<Projects/>} />
      <Route path='/project/:slug' element={<ProjectInner/>} />
      <Route path='/about' element={<AboutPage/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/services' element={<Service/>} />
      <Route path='/service/:slug' element={<ServiceInner/>} />
      <Route path='/blogs' element={<Blog/>} />
      <Route path='/blog/:slug' element={<BlogInner/>} />
      <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
      <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
      <Route path='/thank-you' element={<ThankYou/>} />
      
      <Route path='/*' element={<ErrorPage/>}/>
     </Routes>
     <Loader status={loader}/>
    </>
  );
}

export default App;
