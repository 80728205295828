import React, { createContext, useContext, useReducer } from 'react';

export const HeaderContext = createContext();

const initialState = {
  showed:false,
  chat:false,
  active: false,
  projects:[],
  currentProjectData:{'totalData':0, 'currentData':0,'projects':[]}
};

const reducer = (state, action) => {
  
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        ...state,
        active: !state.active,
      };
    case 'CLOSE_MENU':
      return {
          ...state,
          active: false,
        };
    case 'PROJECTS_SET':
      return {
          ...state,
          'projects':action.payload
        };
    case 'PROJECTS_SET_CURRENT_DATA':
      return {
          ...state,
          'currentProjectData':action.payload
        };
    case 'TOGGLE_SHOW':
      return {
          ...state,
          'showed':action.payload
        };
    case 'TOGGLE_CHAT_SHOWED':
      return {
          ...state,
          'chat':action.payload
        };
    default:
      return state;
  }
};

const StoreContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer,initialState);

  return (
    <HeaderContext.Provider value={{ state, dispatch }}>
      {children}
    </HeaderContext.Provider>
  );
};


export default StoreContext;
