import React from 'react'
import styled from 'styled-components';

function TopArea() {
  return (
    <Section>
        <BreadcrumbArea>
          <Title>
            About /
          </Title>
          <Path>
            Home/About
          </Path>
        </BreadcrumbArea>
        <ContentArea>
          <Heading>
          The Home of Elegant Designing
          </Heading>
          <Para1>
          Home is where the heart is, and at Classic Insides, we aim to create and provide home interiors that embrace the ultimate comfort. Our service model is crafted to make your visions and dreams come alive in style. With a legacy spanning over two decades, we've been a driving force in the interior design industry, serving the whole of India with unwavering commitment to classic and thoughtful design.
          </Para1>
          <Para2>
          We are passionate about bringing revolutionary innovation to the realm of interior designs. As such, Classic Insides is dedicated to not only premium interiors,
          but has also developed an in-house system for building and creating custom interiors for any space. With these capabilities, we are poised as one of the top interior designers in India.
          Our passion is rooted in the art of restoring the true elegance of spaces. Backed by a team of accomplished architects and interior designers, we take pride in our ability to understand your ideas, lifestyle, tastes, and budget, ensuring that the finest designs are meticulously crafted to match your preferences.
          </Para2>
        </ContentArea>
    </Section>
  )
}

export default TopArea;

const Section = styled.div`
    width: 100%;
`
const BreadcrumbArea = styled.div`
    display: flex;
    justify-content:space-between;
`;

const Title = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Path = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const ContentArea = styled.div`
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
`;

const Heading = styled.p`
    padding: 0;
    margin: 0;
    font-size: 50px;
    font-family: 'wensley_bold' !important;
    color: var(--secondary-cl);
    margin-bottom: 25px;
    @media(max-width:1130px){
      font-size:38px;
    };
    @media(max-width:580px){
      font-size:28px;
      br{
        display:none;
      }
    };
    @media(max-width:375px){
      font-size:25px;
    };
`;

const Para1 = styled.p`
    padding: 0;
    margin: 0; 
    margin-top: 15px;
    /* font-family: 'raleway_medium'; */
    font-family: var(--ff);
    font-size: var(--para-fs);
    line-height:var(--line-height);
    @media(max-width:1130px){
      text-align: left;
    };
`;

const Para2 = styled.p`
    padding: 0;
    margin: 0; 
    margin-top: 15px;
    /* font-family: 'raleway_medium'; */
    font-family: var(--ff);
    font-size: var(--para-fs);
    line-height:var(--line-height);
    @media(max-width:1130px){
      /* font-size:14px; */
      text-align: left;
    };
`;
