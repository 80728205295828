
window.addEventListener("scroll",function() {
    const header = document.querySelector('#header');
    const scrollPosition = window.scrollY;
    if(header){
        if (scrollPosition < 20){
            header.style.position = 'sticky';
        }else{
            header.style.position = 'fixed';
        }
    }
  });