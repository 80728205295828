import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {ThreeDots} from 'react-loader-spinner';



function AssetSlider({assets}) {
 const [asset, setasset] = useState(assets);
 const [selectedAsset, setselectedAsset] = useState(0);
 const [isLoading, setIsLoading] = useState(false);


const handleLeftClick = () => {
setIsLoading(true);
const totalLenght = asset.length;

if(selectedAsset-1 < 0){
   setselectedAsset(totalLenght-1);
}else{
   setselectedAsset((prev)=>prev-1);
}
setTimeout(() => {
   setIsLoading(false)
}, 500);

};

const handleRightClick = () => {
   setIsLoading(true);
   const totalLenght = asset.length;

   if(selectedAsset+1 === totalLenght){
      setselectedAsset(0)
   }else{
      setselectedAsset((prev)=>prev+1)
   }
   setTimeout(() => {
      setIsLoading(false)
   }, 500);
};



  return (
   <Div> 
      {isLoading ? (
         <Loader>
         <ThreeDots
               height="80" 
               width="80" 
               radius="9"
               color="#000" 
               ariaLabel="three-dots-loading"
               wrapperStyle={{}}
               wrapperClassName=""
               visible={true}/>
         </Loader>
         ) : asset[selectedAsset]?.asset_type === 'image'?(
          <img loading='lazy'  src={asset[selectedAsset]?.image} alt="" />
      ):(
         <video autoPlay loop muted src={asset[selectedAsset]?.factory_video_computer} ></video>
      )}
      {
         asset?.length > 1 
         &&
         (
         <>
         <ButtonsDiv>
            <LeftButton onClick={handleLeftClick}>
               <ArrowBackIosIcon/>
            </LeftButton>
            <RightButton onClick={handleRightClick}>
               <ArrowForwardIosIcon/>
            </RightButton>
         </ButtonsDiv>
         <IndexData>
         {selectedAsset+1>9?`${selectedAsset+1}`:`0${selectedAsset+1}`} / {asset.length>9?`${asset.length}`:`0${asset.length}`}
         </IndexData>
         </>
         )
      }
      
   </Div>
  )
}

export default AssetSlider;


const Div = styled.div`
  width:100%;
  position:relative;
  height: 100%;
  img{
   width:100%;
   display:block;
   height:100%;
  }
  video { 
   width:100%;
   height:100%;
   object-fit: cover;
  }
`;

const ButtonsDiv = styled.div`
   width: 100%;
   padding:20px 0px;
   display: flex;
   justify-content:space-between;
   position: absolute;
   top:50%;
`;

const LeftButton = styled.button`
background-color: transparent;
border: none;
color: #fff!important;

`;

const RightButton = styled.button`
      background-color: transparent;
      border: none;
      color: #fff!important;
`;

const IndexData = styled.div`
   width: 100px;
   padding:20px 0px;
   display: flex;
   justify-content:space-between;
   position: absolute;
   left: 10px;
   bottom: 5px;
   color:#fff;
   font-family:'raleway_regular';
`;

const Loader = styled.div`
height: 100%;
display: flex;
align-items:center;
justify-content:center;
background-color:#dddada;
opacity: 30%;
`;
