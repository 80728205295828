import React from "react";
import styled from "styled-components";
import {useNavigate,useLocation} from 'react-router-dom';

import Header from "../components/includes/Header";
import Footer from "../components/includes/Footer";
import bg from "../../src/assets/images/thankYou/bg.png";

function ThankYou() {
  const navigate = useNavigate();
  const location = useLocation();
  const access = location.state?.access;

  if(access){
    return (
      <>
        <Header />
        <Section>
          <Wrapper>
            <Div className="col">
              <Heading>Thank You</Heading>
              <Para>for Reaching Out to Classic Insides!!</Para>
            </Div>
            <Div>
              <Para>
                We Appreciate Your Interest! Your form submission has been
                received and is important to us. Our team will be in touch with
                you shortly to discuss your project needs and answer any questions
                you may have. In the meantime, explore some of our other projects
                that might inspire your vision.
              </Para>
            </Div>
            <Div className="btns">
              <Button onClick={()=>navigate('/')}>Go to Home Page</Button>
              <Button className="projects" onClick={()=>navigate('/projects')}>Explore Projects</Button>
            </Div>
          </Wrapper>
        </Section>
        <Footer />
      </>
    );
  }else{
     navigate('/');
  }
}

export default ThankYou;

const Section = styled.section`
  height: calc(125vh - 80px);
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 675px;

  @media screen and (max-width:580px) {
    background-size: cover;
    background-position:center center;
  }
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  padding-top: 85px;

  @media screen and (min-width:2000px) {
    padding-top: 120px;
  }
  @media screen and (max-width:768px) {
    padding-top: 60px;
  }
  @media screen and (max-width:580px) {
    padding-top: 40px;
  }
  @media screen and (max-width:500px) {
    width:90%;
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0px;

  &.col {
    flex-direction: column;

  }

  &.btns{
    gap:15px;
  }

  @media screen and (max-width:475px) {
    &.btns{
    flex-direction: column;
    gap: 10px;
  }
  }
`;

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: "wensley_bold";
  color: rgba(110, 110, 110, 1);
  font-size: 3rem;
  font-weight: unset;

  @media screen and (max-width:580px) {
    font-size: 2.5rem;
  }
`;

const Para = styled.p`
  width: 75%;
  text-align: center;
  margin: 0 auto;
  color: rgba(110, 110, 110, 1);
  line-height: 26px;
  font-size: 15.5px;
  font-family: "raleway_regular";

  @media screen and (max-width:580px) {
    width:85%;
    font-size: 14px;
  }
  @media screen and (max-width:475px) {
    width:100%;
  }
`;

const Button = styled.button`
  border: 1px solid rgba(235, 101, 2, 1);
  padding: 12px 30px;
  color:rgba(110, 110, 110, 1);
  background-color:transparent;
  font-size: 15px;
  font-family: "raleway_regular";
  cursor: pointer;

  &.projects{
    background-color:rgba(235, 101, 2, 1);
    color:white;
  }

  @media screen and (max-width:475px){
    font-size: 14px;
  }
`;
