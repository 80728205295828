import React from 'react'
import Helmet from '../components/utils/Helmet'
import PrivacyPolicyContents from '../components/pages/privacypolicy/PrivacyPolicyContents'
import Header from '../components/includes/Header'
import Footer from '../components/includes/Footer'

function PrivacyPolicy() {
  return (
    <>
    <Helmet
        titleData="Terms And Conditions"
        descriptionData="Terms And Conditions"
      />
      <Header />
      <PrivacyPolicyContents/>
      <Footer />
    </>
  )
}

export default PrivacyPolicy