import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../components/includes/Header";
import BlogBanner from "../components/pages/blog/BlogBanner";
import Footer from "../components/includes/Footer";
import styled from "styled-components";
import Breadecrumb from "../components/pages/blog/Breadecrumb";
import BlogList from "../components/pages/blog/BlogList";
import { ListSingleSeoData } from "../axios/api";
import Helmet from "../components/utils/Helmet";

function Blog() {
  const [seoData, setseoData] = useState({});
  const path = window.location.pathname;

  // get seo Data
  useEffect(() => {
    axios
      .get(ListSingleSeoData, {
        params: {
          links: path,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data.app_data;

        if (StatusCode === 6000) {
          setseoData(data[0]);
        } else {
          setseoData({});
        }
      });
  }, []);
  
  return (
    <>
      <Helmet
        titleData={seoData?.meta_title}
        descriptionData={seoData?.meta_description}
      />
      <Header />
      <BlogBanner />
      <Section>
        <Breadecrumb />
        <BlogList />
      </Section>
      <Footer />
    </>
  );
}

export default Blog;

const Section = styled.div`
  width: 80%;
  margin: 0 auto;

  @media (max-width: 425px) {
    width: 85%;
  }
`;
