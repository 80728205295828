import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

function AutoSlideImage({ assets }) {
  const [images, setimages] = useState(assets);
  const [current, setcurrent] = useState(0);
  const [intervalId, setintervalId] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      setcurrent((prev) => {
        if (prev + 1 === images.length) {
          return 0;
        } else {
          const newVal = prev + 1;

          return newVal;
        }
      });
    }, 2500);
    setintervalId(id);
    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  }, [images.length]);
  return <Img  src={images[current]?.image} />;
}

export default AutoSlideImage;

const Img = styled.div`
  /* height:320px; */
  height: 95%;
  ${(props) => props.src && `background-image: url(${props.src});`}
  background-repeat:no-repeat;
  background-size: cover;
  transition: background-image 0.5s ease-in;
`;
