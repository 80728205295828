import React, { useEffect, useState } from "react";
import Header from "../components/includes/Header";
import Footer from "../components/includes/Footer";
import { styled } from "styled-components";
import FactoryBanner from "../components/pages/factory/FactoryBanner";
import FactoryInfo from "../components/pages/factory/FactoryInfo";
import axios from "axios";
import { FactoryDetailsApi, ListSingleSeoData } from "../axios/api";
import Helmet from "../components/utils/Helmet";

function Factory() {
  const [factoryInfos, setfactoryInfos] = useState({});
  const [seoData, setseoData] = useState({});
  const path = window.location.pathname;

  // get seo Data
  useEffect(() => {
    axios
      .get(ListSingleSeoData, {
        params: {
          links: path,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data.app_data;

        if (StatusCode === 6000) {
          setseoData(data[0]);
        } else {
          setseoData({});
        }
      });
  }, []);

  useEffect(() => {
    axios.get(FactoryDetailsApi).then((response) => {
      const { data, StatusCode } = response.data.app_data;

      if (StatusCode === 6000) {
        setfactoryInfos(data[0]);
      }
    });
  }, []);

  return (
    <>
      <Helmet
        titleData={seoData?.meta_title}
        descriptionData={seoData?.meta_description}
      />
      <Header />
      <Section>
        <FactoryBanner data={factoryInfos} />
        <FactoryInfo data={factoryInfos} />
      </Section>
      <Footer />
    </>
  );
}

export default Factory;

const Section = styled.div``;
