import React, { useState } from 'react'
import Header from '../components/includes/Header';
import TopInfoSection from '../components/pages/projectinner/TopInfoSection';
import Footer from '../components/includes/Footer';
import { styled } from 'styled-components';
import TopContent from '../components/pages/projectinner/TopContent';
import AssetArea from '../components/pages/projectinner/AssetArea';
import BottomContactUs from '../components/pages/projectinner/BottomContactUs';
import { useEffect } from 'react';
import { useParams} from 'react-router-dom';
import axios from 'axios';
import { ListSingleProjectApi } from '../axios/api';
import Helmet from '../components/utils/Helmet';


function ProjectInner() {
  const [project, setproject] = useState(null);
  const {slug} = useParams();

  useEffect(() => {
   axios.get(ListSingleProjectApi+slug).then((response)=>{
      const { data , StatusCode}  = response.data.app_data;
      
      if(StatusCode === 6000){
         setproject(data[0])
      }else{
         setproject({})
      }
   })
  }, [slug])
  
  return (
    <>
    <Helmet
        titleData={project?.meta_tag}
        descriptionData={project?.meta_description}
      />
    <Header/>
    <Section >
        <TopInfoSection item={project}/>
        <TopContent item={project}/>
        <AssetArea item={project}/>
    </Section>
    <BottomContactUs/>
    <Footer/>
    </>
  )
}

export default ProjectInner;


const Section = styled.div`
width: 80%;
margin: 0 auto;

@media(min-width:2000px){
   width: 85%;
}
@media(max-width:1440px){
   width: 90%;
}

@media(max-width:1000px){
   width: 98%;
}
`;