import React from 'react';
import { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/includes/Header';
import Footer from '../components/includes/Footer';
import styled from 'styled-components';
import ContentSection from '../components/pages/sectionInner/ContentSection';
import Banner from '../components/pages/sectionInner/Banner';
import MediaArea from '../components/pages/sectionInner/MediaArea';
import Faqs from '../components/pages/sectionInner/Faqs';
import { ListSingleService } from '../axios/api';
import Helmet from '../components/utils/Helmet';
import SeoArea from '../components/pages/home/SeoArea';

function ServiceInner() {
  const [service, setservice] = useState({});
  const { slug } = useParams();
  
  useEffect(() => {
    axios.get(ListSingleService+slug)
    .then((response) =>{
      const { data , StatusCode } = response.data.app_data;

      if(StatusCode === 6000){
        setservice(data[0])
      }else{
        setservice({})
      }
    })
  },[])
  
  return (
    <>
    <Helmet titleData={service?.meta_title} descriptionData={service?.meta_description}/>
    <Header/>
    <Banner item={service}/>
    <Section>
        <ContentSection item={service}/>
        <MediaArea item={service}/>
        <Faqs item={service}/>
        <SeoArea/>
    </Section>
    <Footer/>
    </>
  )
}

export default ServiceInner;

const Section = styled.div`
    margin: 25px auto 55px;
    display: flex;
    flex-direction: column;
    gap:40px;

    @media(max-width:1200px){
      margin: 40px auto;
    }

`;