import React from 'react'
import styled from 'styled-components'

function Contents() {
    return (
        <Section>
            <MainHeading>Terms and Conditions</MainHeading>
            {/* <Heading>sdfds</Heading> */}
            <SubHeading>Welcome to Classic Insides!</SubHeading>
            <Content>These terms and conditions outline the rules and regulations for the use of Classic
                Insides&#39;s Website, located at <a href="https://classicinsides.com/" target='_blank'>https://classicinsides.com/</a>.</Content>
            <Content>
                By accessing this website we assume you accept these terms and conditions. Do not
                continue to use Classic Insides if you do not agree to take all of the terms and
                conditions stated on this page.
            </Content>
            <Content>
                The following terminology applies to these Terms and Conditions, Privacy Statement
                and Disclaimer Notice and all Agreements: &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to you,
                the person log on this website and compliant to the Company&#39;s terms and conditions.
                &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to our Company. &quot;Party&quot;,
                &quot;Parties&quot;, or &quot;Us&quot;, refers to both the Client and ourselves. All terms refer to the offer,
                acceptance and consideration of payment necessary to undertake the process of our
                assistance to the Client in the most appropriate manner for the express purpose of
                meeting the Client&#39;s needs in respect of provision of the Company&#39;s stated services,
                in accordance with and subject to, prevailing law of in. Any use of the above
                terminology or other words in the singular, plural, capitalization and/or he/she or they,
                are taken as interchangeable and therefore as referring to same.
            </Content>

            <Heading>Cookies </Heading>
            <Content>
                We employ the use of cookies. By accessing Classic Insides, you agreed to use
                cookies in agreement with the Classic Insides&#39;s Privacy Policy.
            </Content>
            <Content>
                Most interactive websites use cookies to let us retrieve the user&#39;s details for each
                visit. Cookies are used by our website to enable the functionality of certain areas to
                make it easier for people visiting our website. Some of our affiliate/advertising
                partners may also use cookies.
            </Content>

            <Heading>License </Heading>
            <Content>Unless otherwise stated, Classic Insides and/or its licensors own the intellectual
                property rights for all material on Classic Insides. All intellectual property rights are
                reserved. You may access this from Classic Insides for your own personal use
                subjected to restrictions set in these terms and conditions.
            </Content>
            <Points>
                <Content>You must not:</Content>
                <UL>
                    <LI>Republish material from Classic Insides</LI>
                    <LI>Sell, rent or sub-license material from Classic Insides</LI>
                    <LI>Reproduce, duplicate or copy material from Classic Insides</LI>
                    <LI>Redistribute content from Classic Insides</LI>
                </UL>
            </Points>
            <Content>
                This Agreement shall begin on the date hereof. Our Terms and Conditions were
                created with the help of the Free Terms and Conditions Generator.
            </Content>
            <Content>
                Parts of this website offer an opportunity for users to post and exchange opinions
                and information in certain areas of the website. Classic Insides does not filter, edit,
                publish or review Comments prior to their presence on the website. Comments do
                not reflect the views and opinions of Classic Insides,its agents and/or affiliates.
                Comments reflect the views and opinions of the person who post their views and
                opinions. To the extent permitted by applicable laws, Classic Insides shall not be
                liable for the Comments or for any liability, damages or expenses caused and/or
                suffered as a result of any use of and/or posting of and/or appearance of the
                Comments on this website.
            </Content>
            <Content>
                Classic Insides reserves the right to monitor all Comments and to remove any
                Comments which can be considered inappropriate, offensive or causes breach of
                these Terms and Conditions.
            </Content>
            <Points>
                <Content>You warrant and represent that:</Content>
                <UL>
                    <LI>You are entitled to post the Comments on our website and have all necessary
                        licenses and consents to do so</LI>
                    <LI>The Comments do not invade any intellectual property right, including without
                        limitation copyright, patent or trademark of any third party</LI>
                    <LI>The Comments do not contain any defamatory, libelous, offensive, indecent or
                        otherwise unlawful material which is an invasion of privacy</LI>
                    <LI>The Comments will not be used to solicit or promote business or custom or
                        present commercial activities or unlawful activity.</LI>
                </UL>
            </Points>
            <Content>
                You hereby grant Classic Insides a non-exclusive license to use, reproduce, edit and
                authorize others to use, reproduce and edit any of your Comments in any and all
                forms, formats or media.
            </Content>

            <Heading>Hyperlinking to our Content</Heading>
            <Content>
                The following organizations may link to our Website without prior written approval:
            </Content>
            <Points>
                <UL>
                    <LI>Government agencies</LI>
                    <LI>Search engines</LI>
                    <LI>News organizations</LI>
                    <LI>Online directory distributors may link to our Website in the same manner as
                        they hyperlink to the Websites of other listed businesses</LI>
                    <LI>System wide Accredited Businesses except soliciting non-profit organizations,
                        charity shopping malls, and charity fundraising groups which may not
                        hyperlink to our Web site.</LI>

                </UL>
            </Points>
            <Content>
                These organizations may link to our home page, to publications or to other Website
                information so long as the link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking party&#39;s site.
            </Content>
            <Content>
                These organizations may link to our home page, to publications or to other Website
                information so long as the link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking party&#39;s site.
            </Content>
            <Content>
                We may consider and approve other link requests from the following types of
                organizations:
            </Content>
            <Points>
                <UL>
                    <LI>commonly-known consumer and/or business information sources</LI>
                    <LI>dot.com community sites</LI>
                    <LI>associations or other groups representing charities</LI>
                    <LI>online directory distributors</LI>
                    <LI>internet portals</LI>
                    <LI>accounting, law and consulting firms, and educational institutions and trade associations.</LI>
                </UL>
            </Points>
            <Content>
                We will approve link requests from these organizations if we decide that: (a) the link
                would not make us look unfavorably to ourselves or to our accredited businesses; (b)
                the organization does not have any negative records with us; (c) the benefit to us
                from the visibility of the hyperlink compensates the absence of Classic Insides; and
                (d) the link is in the context of general resource information.
            </Content>
            <Content>
                These organizations may link to our home page so long as the link: (a) is not in any
                way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of
                the linking party and its products or services; and (c) fits within the context of the
                linking party&#39;s site.
            </Content>
            <Content>
                If you are one of the organizations listed in paragraph 2 above and are interested in
                linking to our website, you must inform us by sending an e-mail to Classic Insides.
                Please include your name, your organization name, contact information as well as
                the URL of your site, a list of any URLs from which you intend to link to our Website,
                and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a
                response.
            </Content>
            <Content>Approved organizations may hyperlink to our Website as follows:</Content>
            <Points>
                <UL>
                    <LI>By use of our corporate name, or</LI>
                    <LI>By use of the uniform resource locator being linked to, or</LI>
                    <LI>By use of any other description of our Website being linked to that makes
                        sense within the context and format of content on the linking party&#39;s site.</LI>
                </UL>
            </Points>
            <Content>No use of Classic Insides&#39;s logo or other artwork will be allowed for linking absent a
                trademark license agreement.</Content>

            <Heading>iFrames</Heading>
            <Content>
                Without prior approval and written permission, you may not create frames around our
                Webpages that alter in any way the visual presentation or appearance of our
                Website.
            </Content>
            <Heading>Content Liability</Heading>
            <Content>
                We shall not be hold responsible for any content that appears on your Website. You
                agree to protect and defend us against all claims that is rising on your Website. No
                link(s) should appear on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates the infringement or
                other violation of, any third party rights.
            </Content>
            <Heading>Reservation of Rights</Heading>
            <Content>
                We reserve the right to request that you remove all links or any particular link to our
                Website. You approve to immediately remove all links to our Website upon request.
                We also reserve the right to amen these terms and conditions and it&#39;s linking policy
                at any time. By continuously linking to our Website, you agree to be bound to and
                follow these linking terms and conditions.
            </Content>
            <Heading>Removal of links from our website</Heading>
            <Content>
                If you find any link on our Website that is offensive for any reason, you are free to
                contact and inform us any moment. We will consider requests to remove links but we
                are not obligated to or so or to respond to you directly.
            </Content>
            <Content>
                We do not ensure that the information on this website is correct, we do not warrant
                its completeness or accuracy; nor do we promise to ensure that the website remains
                available or that the material on the website is kept up to date.
            </Content>
            <Heading>Disclaimer</Heading>
            <Content>
                To the maximum extent permitted by applicable law, we exclude all representations,
                warranties and conditions relating to our website and the use of this website. Nothing
                in this disclaimer will:
            </Content>
            <Points>
                <UL>
                    <LI>limit or exclude our or your liability for death or personal injury</LI>
                    <LI>limit or exclude our or your liability for fraud or fraudulent misrepresentation</LI>
                    <LI>limit any of our or your liabilities in any way that is not permitted under
                        applicable law, or</LI>
                    <LI>exclude any of our or your liabilities that may not be excluded under
                        applicable law.</LI>
                </UL>
            </Points>
            <Content>
                The limitations and prohibitions of liability set in this Section and elsewhere in this
                disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities
                arising under the disclaimer, including liabilities arising in contract, in tort and for
                breach of statutory duty.
            </Content>
            <Content>
                As long as the website and the information and services on the website are provided
                free of charge, we will not be liable for any loss or damage of any nature.
            </Content>
        </Section>
    )
}

export default Contents

const Section = styled.div`
    padding: 50px 0px;
    font-family: 'raleway_semibold';
    width: 70%;
    margin: auto;
    line-height: 25px;
    @media (max-width: 1023px) {
    width: 80%;
    }
    @media (max-width: 767px) {
    width: 90%;
    padding: 20px 0px;
    }


`
const Points = styled.div``
const MainHeading = styled.h1`
    line-height: 35px;
    @media (max-width: 767px) {
    text-align: center;
    }
`
const Heading = styled.h2`
    
`
const SubHeading = styled.h4``
const Content = styled.p`
    font-family: 'raleway_medium';

`
const UL = styled.ul`
    font-family: 'raleway_medium';
`
const LI = styled.li`
    font-family: 'raleway_medium';

`