import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../components/includes/Header";
import ContactBanner from "../components/pages/contact/ContactBanner";
import styled from "styled-components";
import TopContent from "../components/pages/contact/TopContent";
import MiddleSection from "../components/pages/contact/MiddleSection";
import BottomSection from "../components/pages/contact/BottomSection";
import Footer from "../components/includes/Footer";
import { ListSingleSeoData } from "../axios/api";
import Helmet from "../components/utils/Helmet";

function Contact() {
  const [seoData, setseoData] = useState({});
  const path = window.location.pathname;

  // get seo Data
  useEffect(() => {
    axios
      .get(ListSingleSeoData, {
        params: {
          links: path,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data.app_data;

        if (StatusCode === 6000) {
          setseoData(data[0]);
        } else {
          setseoData({});
        }
      });
  },[]);

  return (
    <>
      <Helmet
        titleData={seoData?.meta_title}
        descriptionData={seoData?.meta_description}
      />
      <Header />
      <ContactBanner />
      <Section>
        <TopContent />
        <MiddleSection />
        <BottomSection />
      </Section>
      <Footer />
    </>
  );
}

export default Contact;

const Section = styled.div`
  width: 80%;
  margin: 25px auto;
  @media (max-width: 1025px) {
    width: 90%;
  }
`;
