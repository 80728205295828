import React from 'react'
import styled from 'styled-components';

function Breadecrumb() {
  return (
    <BreadcrumbArea>
          <Title>
            Blogs /
          </Title>
          <Path>
            Home/Blogs
          </Path>
    </BreadcrumbArea>
  )
}

export default Breadecrumb;


const BreadcrumbArea = styled.div`
    display: flex;
    justify-content:space-between;
    margin: 25px 0px;
`;

const Title = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Path = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;