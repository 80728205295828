import React, { useState } from "react";
import styled from "styled-components";

import uparrow from "../../../assets/images/serviceInner/icons/up-arrow.svg";
import downarrow from "../../../assets/images/serviceInner/icons/down-arrow.svg";
import { acordianData } from "../../../assets/data/testData";

function Faqs({item}) {
  const [active, setactive] = useState(null);

  const handleActiveToggle = (index) => {
    if (active === index) {
      setactive(null);
    } else {
      setactive(index);
    }
  };

  return (
    <Section className={`${item?.faq?.length === 0 && 'hide'}`}>
      <Title>Frequently Asked Questions</Title>
      <Container>
        {item?.faq?.map((item, indx) => (
          <AccodianTab>
            <AccordionHead onClick={() => handleActiveToggle(indx)}>
              <Name>{"-" + item.faq_question}</Name>
              <Arrow>
                <img src={active === indx ? uparrow : downarrow} alt="" />
              </Arrow>
            </AccordionHead>
            {active === indx && <AccordionBody>{item.faq_answer}</AccordionBody>}
          </AccodianTab>
        ))}
      </Container>
    </Section>
  );
}

export default Faqs;

const Section = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &.hide{
    display: none;
  }

  @media(max-width:580px){
        width:90%
  }
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  color: #000;
  font-family: "wensley_light";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media(max-width:580px){
    font-size: 25px;
  }

  @media(max-width:350px){
    font-size: 21px;
    text-align: center;
  }

  
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;

const AccodianTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const AccordionHead = styled.div`
  display: flex;
  justify-content: space-between;
  cursor:pointer;
  gap:25px;

`;
const AccordionBody = styled.div`
  color: #000;
  text-align: justify;
  font-family: "raleway_regular";
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */

  @media(max-width:600px){
    font-size: 13.5px;
  }
  @media(max-width:350px){
    font-size: 13px;
  }
`;

const Name = styled.p`
  margin: 0;
  padding: 0;
  color: #000;
  text-align: justify;
  font-family: "raleway_regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  @media(max-width:600px){
    font-size: 16px;
  }
  @media(max-width:500px){
    font-size: 15px;
    line-height: 25px;
  }
  @media(max-width:350px){
    font-size: 14px;
  }
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 13px;
    height: 7px;
  }
`;
