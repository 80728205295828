import React from 'react'
import { styled } from 'styled-components';
import axios from 'axios';

import contactbg from '../../../assets/images/contact/Contact.jpg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryEnquiry } from '../../../axios/api';
import {ThreeDots} from 'react-loader-spinner';
import tick from '../../../assets/images/icons/tick.svg';

function ContactSection() {
  const [formData, setformData] = useState({
        name:"",
        phone:'',
        email:'',
  })

  const [error, seterror] = useState('');
  const [done, setdone] = useState(false);
  const [loading, setloading] = useState(false);
  const [uploaded, setuploaded] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
      const { name } = e.target;
     
      if(name === "upload_plan"){
         if(e.target?.files[0]){
          setformData({
            ...formData,
            [name]: e.target.files[0]
           })
           setuploaded(e.target.files[0].name)
         }else{
           setuploaded(false)
         }
         
      }else{
        setformData({
          ...formData,
          [name]: e.target.value
         })
      }
  };

  const assignHandler = (e) => {
        var mobilePattern = /^[6789]\d{9}$/;
        var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        e.preventDefault();

        const form = new FormData();

        if(formData.name === "" || formData.name.length < 3){
           seterror("Enter a valid name")
           setTimeout(()=>{
            seterror('')
            },3000)
        }else if(formData.email === "" || !emailPattern.test(formData.email)){
          seterror("Enter a valid email")
          setTimeout(()=>{
           seterror('')
           },3000)
        }else if(formData.phone === "" || !mobilePattern.test(formData.phone)){

          seterror("Enter a valid phone number")
          setTimeout(()=>{
           seterror('')
           },3000)
        }else{
          Object.entries(formData).forEach((obj)=>{
              form.append(obj[0], obj[1])
          })
          setloading(true)
          axios.post(PrimaryEnquiry,form)
          .then((response)=>{
           const { StatusCode , data }  = response.data.app_data;
           setloading(false)
           if(StatusCode === 6000){
              // setdone(true);
              e.target.reset();
              setformData({
                name:"",
                phone:'',
                email:'',
                message:''
              })
              setuploaded(false)
              // setTimeout(()=>{
              // setdone(false);
              // },3000)
              navigate('/thank-you',{
                state:{
                  access:true,
                }
              })
           }else{
            e.target.reset();
           }
          })
        }
  };
  
  return (
    <Section>
        <Container>
            <BreadCrumb>
            Contact /
            </BreadCrumb>
            <Fields onSubmit={(e)=>assignHandler(e)}>
              {
                done && <Success className='submited-alert'>
                 Enquiry Send Successfully. We'll connect you soon.
                </Success>
              }
              {
                error && (
                  <Error>
                    {error}
                  </Error>
                ) 
              }
                <NameInput  onChange={(e)=>handleChange(e)} value={formData?.name} name='name' placeholder='Name'/>

                <PhoneInput onChange={(e)=>handleChange(e)} value={formData?.phone} name="phone" placeholder='Phone'/>

                <EmailInput onChange={(e)=>handleChange(e)} placeholder='Email' value={formData?.email} name="email"/>

                <CustomFileInput>
                <label for="fileInput" className={uploaded ? 'active':''} >
                  {
                    uploaded ? uploaded.length > 35 ? uploaded.slice(0,35) + '...' : uploaded : "Choose Plan"
                  }
                  
                </label>
                <ChosseFile  onChange={(e)=>handleChange(e)}  name='upload_plan' id='fileInput' placeholder='Select Paln' type='File'/>
                {
                  uploaded && <Tick src={tick}/>
                }
                
                </CustomFileInput>

                <MessageInput  onChange={(e)=>handleChange(e)} value={formData?.message} name='message' placeholder='Message'/>
                 
                <SubmitButton>
                  {loading ? (
                    <ThreeDots 
                    height="20" 
                    width="30" 
                    radius="3"
                    color="#ffffff" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}/>
                    
                  ) :"Submit"}
                </SubmitButton>
                
            </Fields>
        </Container>
    </Section>
  )
}

export default ContactSection;

const Section = styled.div`
  padding:43px;
  background:url(${contactbg}) 50% 50% no-repeat;
  height:500px;
  justify-content: flex-start;
  margin:0px 0px;
  background-repeat: no-repeat;
  @media(min-width:1875px){
    background-size: cover;
  }
  @media(max-width:580px){
    padding: 20px;
  }
`;

const Container = styled.div`
   display: flex;
   flex-direction: column;
   width:30%;
   padding: 0px 100px;
   @media(min-width:1875px){
    padding: 0px 160px;
  }
  @media(max-width:1024px){
    padding: 0px 20px;
    width:50%;
  }
  @media(max-width:580px){
    padding: 0px 0px;
    width:95%;
  }
`;

const BreadCrumb = styled.div`
   display: flex;
   align-items:start;
   justify-content: baseline;
   padding: 10px 0px;
   width:75%;
   font-family: 'raleway_light';
   font-size: 15px;
   color:#7f7f7f;
`;

const Fields = styled.form`
   display: flex;
   flex-direction: column;
   padding:20px 0px;
   .file-label{
    color:#7f7f7f !important;
   }
`;
const NameInput = styled.input`
  margin:10px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  @media(min-width:1875px){
      height: 32px;
  }
  &::placeholder{
    color: #7f7f7f;
    font-family: 'raleway_regular';
  }
`;
const PhoneInput = styled.input`
    margin:10px 0px;
    height: 30px;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 5px 10px;
    color: #fff;
    @media(min-width:1875px){
      height: 32px;
    }
    &::placeholder{
    color: #7f7f7f;
    font-family: 'raleway_regular';
   }
`;
const EmailInput = styled.input`
  margin:10px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  @media(min-width:1875px){
    height: 32px;
  }
  &::placeholder{
    color: #7f7f7f;
    font-family: 'raleway_regular';
  }
`;

const ChosseFile = styled.input`
    margin:10px 0px;
    height: 30px;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 5px 10px;
    color: #fff;
    visibility: hidden;
    width: 10px;
    @media(min-width:1875px){
      height: 32px;
    }
    &::placeholder{
    color: #7f7f7f;
    }
    /* &::file-selector-button{
        display:none;
    } */
`;

const MessageInput = styled.textarea`
    margin:10px 0px;
    height: 75px;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 5px 10px;
    color: #fff;
    width: 95%;
    resize:vertical;
    
    @media(min-width:1875px){
      width: 97.5%;
    }
    @media(min-width:1024px){
      width: 95.5%;
    }
    @media(max-width:580px){
      width: 94%;
    }
    @media(max-width:400px){
      width: 92%;
    }
    &::placeholder{
    color: #7f7f7f;
    font-family: 'raleway_regular';
    }
`;

const SubmitButton = styled.button`
  width: 30%;
  background-color: transparent;
  margin:10px 0px;
  padding: 15px 20px;
  color: #fff;
  border:1px solid orange;
  font-family: 'raleway_regular';
  display: flex;
  align-items:center;
  justify-content: center;
  @media(min-width:1024px){
      width: 40%;
  }
  @media(max-width:580px){
    width: 50%;
    margin:10px auto;
  }
`;

const CustomFileInput = styled.div`
    margin:10px 0px;
    height: 30px;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 5px 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items:center;
    @media(min-width:1875px){
      height: 32px;
    }
    &::placeholder{
    color: #7f7f7f;
    font-family: 'raleway_regular';
    }

    label{
      background-color: #ffff;
      color:#000  !important;
      font-size: 13px;
      padding: 2px 10px;
      font-family: 'raleway_regular';
      display:flex;
      align-items: center;
      justify-content: center;
      /* width:100px; */
      @media(max-width:580px){
       width:130px;
      }
      .active{
        background-color: #ffff;
      }
    }
`;

const Error = styled.p`
      width:100%;
      text-align: center;
      font-size:14px;
      padding: 0;
      color:red;
      margin:1px 0px;
      font-family:'raleway_regular';
`;

const Success = styled.p`
      width:100%;
      text-align: center;
      font-size:14px;
      padding: 0;
      color:var(--primary-cl);
      font-family:'raleway_regular';
`;

const LoaderIcon = styled.img`
      width:25px;
      animation: rotate 2s linear infinite;

      @keyframes rotate {
      0%{
        transform: rotate(0deg);
      }
      100%{
        transform: rotate(360deg);
      }
      }
`;

const Tick = styled.img`
      width:20px;
`;