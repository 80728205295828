import React from "react";
import styled from "styled-components";

function ContentSection({item}) {
  return (
    <Section>
      <BreadcrumbArea>
        <BTitle>Services /</BTitle>
        <Path>Home/Services</Path>
      </BreadcrumbArea>
      <Container>
        <Heading>{item?.title}</Heading>
        <Para dangerouslySetInnerHTML={{__html:item?.description}}>
        </Para>
      </Container>
    </Section>
  );
}

export default ContentSection;

const Section = styled.div`
      display: flex;
      flex-direction: column;
      gap:40px;
      width: 80%;
      margin: 0 auto;

      @media(max-width:580px){
        width:90%
      }
`;

const BreadcrumbArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BTitle = styled.p`
  font-family: "raleway_light";
  font-size: 14px;
`;

const Path = styled.p`
  font-family: "raleway_light";
  font-size: 14px;
`;

const Container = styled.div`
      display: flex;
      flex-direction: column;
      gap:21px;
`;

const Heading = styled.h1`
        color: #000;
        font-family:'wensley_light';
        font-size: 68px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
        padding: 0;
        letter-spacing: 1.5px;

        @media(max-width:580px){
            font-size: 50px;
        }
        @media(max-width:350px){
            font-size: 45px;
            text-align:center;
        }
`;

const Para = styled.p`
        color: #000;
        text-align: justify;
        font-style: normal;
        font-weight: 400;
        font-family: var(--ff);
        font-size: var(--para-fs);
        line-height: var(--line-height);
`;
