import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import close from "../../assets/images/icons/close.svg";
import { useNavigate } from 'react-router-dom';
import { PrimaryEnquiry, QuickEnquiry } from "../../axios/api";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

function ContactUsModal({ isModal, setModal}) {
  const [formData, setformData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const [error, seterror] = useState("");
  const [done, setdone] = useState(false);
  const [loading, setloading] = useState(false);
  const [uploaded, setuploaded] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name } = e.target;

    setformData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const assignHandler = (e) => {
    var mobilePattern = /^[6789]\d{9}$/;
    var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    e.preventDefault();
  
    const form = new FormData();

    if (formData.name === "" || formData.name.length < 3) {
      seterror("Enter a valid name");
      setTimeout(() => {
        seterror("");
      }, 3000);
    } else if (formData.email === "" || !emailPattern.test(formData.email)) {
      seterror("Enter a valid email");
      setTimeout(() => {
        seterror("");
      }, 3000);
    } else if (formData.phone === "" || !mobilePattern.test(formData.phone)) {
      seterror("Enter a valid phone number");
      setTimeout(() => {
        seterror("");
      }, 3000);
    } else {
      Object.entries(formData).forEach((obj) => {
        form.append(obj[0], obj[1]);
      });
      setloading(true);

      axios.post(QuickEnquiry,form).then((response) => {
        const { StatusCode, data } = response.data.app_data;
        setloading(false);
        if (StatusCode === 6000) {
          // setdone(true);
          e.target.reset();
          setformData({
            name: "",
            phone: "",
            email: "",
          });
          
          navigate('/thank-you',{
            state:{
              access:true,
            }
          });
          // setdone(false);
          // setTimeout(() => {
          //   setdone(false);
          //   setModal(false);
          // },3000);
          
        } else {
          e.target.reset();
        }
      });
    }
  };

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <CloseIcon src={close} onClick={() => setModal(false)} />
        <Containers>
          <Head>Enquire Now</Head>
          <Fields onSubmit={(e)=>{
             assignHandler(e)
          }}>
            {done && (
              <Success>
                Enquiry Send Successfully. We'll connect you soon.
              </Success>
            )}
            {error && <Error>{error}</Error>}
            <NameInput required type="text" onChange={(e)=>handleChange(e)} value={formData?.name} name='name' placeholder="Name" />

            <PhoneInput required type="number" onChange={(e)=>handleChange(e)} value={formData?.phone} name='phone' placeholder="Phone" />

            <EmailInput required type="email" onChange={(e)=>handleChange(e)} value={formData?.email} name='email' placeholder="Email" />

            <SubmitButton className="flex">
            {loading ? (
                    <ThreeDots 
                    height="20" 
                    width="30" 
                    radius="3"
                    color="#ffffff" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}/>
                    
                  ) :"Submit"}
            </SubmitButton>
          </Fields>
        </Containers>
      </Modal>
    </Container>
  );
}
export default ContactUsModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: var(--lightgrey);
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 4px 2px 40px #000;
  overflow-y: scroll;
  background-color: #2b2b2b;

  &::-webkit-scrollbar {
    display: none;
  }
  /* @media all and (max-width: 1380px) {
    left: 58%;
  } */
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;

const Containers = styled.div`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media all and (max-width: 1300px) {
    padding: 30px;
  }

  @media all and (max-width: 580px) {
    padding: 15px;
  }
`;

const Fields = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  .file-label {
    color: #7f7f7f !important;
  }
`;
const NameInput = styled.input`
  margin: 10px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
    font-family: "raleway_regular";
  }
`;
const PhoneInput = styled.input`
  margin: 10px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
    font-family: "raleway_regular";
  }
`;
const EmailInput = styled.input`
  margin: 10px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
    font-family: "raleway_regular";
  }
`;

const ChosseFile = styled.input`
  margin: 10px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  display: none;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
  }
`;

const MessageInput = styled.textarea`
  margin: 10px 0px;
  height: 75px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  width: 95%;
  @media (min-width: 1875px) {
    width: 97.5%;
  }
  @media (min-width: 1024px) {
    width: 95.5%;
  }
  @media (max-width: 580px) {
    width: 94%;
  }
  @media (max-width: 400px) {
    width: 92%;
  }
  &::placeholder {
    color: #7f7f7f;
    font-family: "raleway_regular";
  }
`;

const SubmitButton = styled.button`
  width: 30%;
  background-color: transparent;
  margin: 10px 0px;
  padding: 15px 20px;
  color: #fff;
  border: 1px solid orange;
  font-family: "raleway_regular";
  @media (min-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 580px) {
    width: 50%;
    margin: 10px auto;
  }
`;

const CustomFileInput = styled.div`
  margin: 10px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
    font-family: "raleway_regular";
  }

  label {
    background-color: #ffff;
    color: #000 !important;
    font-size: 13px;
    padding: 2px 10px;
    font-family: "raleway_regular";
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "raleway_medium";
  color: var(--primary-cl);
`;

const CloseIcon = styled.img`
  width: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  border: 1px solid black;
  border-radius: 50%;
`;

const Error = styled.p`
      width:100%;
      text-align: center;
      font-size:14px;
      padding: 0;
      color:red;
      margin:1px 0px;
      font-family:'raleway_regular';
`;

const Success = styled.p`
      width:100%;
      text-align: center;
      font-size:14px;
      padding: 0;
      color:var(--primary-cl);
      font-family:'raleway_regular';
`;
