import React from 'react'
import Slider from 'react-slick';
import styled from 'styled-components';
import { mediaData } from '../../../assets/data/testData';
import leftArrow from '../../../assets/images/serviceInner/icons/left-arrow.svg';
import rightArrow from '../../../assets/images/serviceInner/icons/right-arrow.svg';

function MediaArea({item}) {
    const settings = {
        arrows:true, 
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear"
      };
    

  return (
    <Section className={`${item?.images?.length === 0 && 'hide'}`}>
        <Slider {...settings}>
            {
            item?.images?.map((itm)=>(
                <Item key={itm.id}>
                    <img src={itm.images} alt="" />
                </Item>
            ))
            }
        </Slider>
    </Section>
  )
}

export default MediaArea;

const Section = styled.section`
    height: auto;
    width:100%;

    &.hide{
        display: none;
    }

    .slick-slider{
        width: 100%;
        height: 100%;
    }

    .slick-list{
        width:100%;
        height: 100%;
    }

    .slick-next{
       width:75px;
       right: 170px;
       height: 50px;
       z-index:10;
       top: unset;
       bottom: 50px;
    }

    .slick-prev{
       width:75px;
       top: unset;
       bottom: 50px;
       right: 250px;
       height: 50px;
       z-index:10;
       left:unset;
    }

    .slick-next:before{
        content: '';
        background-image: url(${rightArrow});
        background-repeat: no-repeat;
        background-size:contain;
        display: block;
        height: 50px;

    };
    .slick-prev:before{
        content: '';
        background-image: url(${leftArrow});
        background-repeat: no-repeat;
        background-size:contain;
        display: block;
        height: 50px;
    }

    @media(max-width:1200px){
    .slick-next{
       bottom: 20px;
       right: 120px;
    }

    .slick-prev{
       bottom: 20px;
       right:200px;
    }

    }

    @media(max-width:600px){
    .slick-next{
       bottom: 0;
       right: 70px;
       width:50px;
       height: 40px;
    }
    
    .slick-next::before{
       height: 40px;
    }

    .slick-prev::before{
       height: 40px;
    }


    

    .slick-prev{
       bottom: 0;
       right:150px;
       width:50px;
       height: 40px;
    }

    }
`;

const Item = styled.div`
      width:100%;
      height: 100%;

      img{
        width:100%;
        object-fit: cover;
        height: 100%;

        @media(max-width:580px){
            min-height: 400px;
        }
      }
`;