import React from 'react'
import styled from 'styled-components';

function TopContent() {
  return (
    <Section>
        <BreadcrumbArea>
          <Title>
            Contact /
          </Title>
          <Path>
            Home/Contact
          </Path>
        </BreadcrumbArea>
        <Heading>
        Craft your home into a haven <br /> Contact us to know more.
        </Heading>
    </Section>
  )
}

export default TopContent;

const Section = styled.div`
    width: 100%;
`
const BreadcrumbArea = styled.div`
    display: flex;
    justify-content:space-between;
`;

const Title = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Path = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Heading = styled.p`
      font-size:40px;
      /* width:40ch; */
      font-family:'wensley_regular';
      margin-top: 13px;
      font-weight: 100 !important;
      text-transform: uppercase;
      @media(max-width:580px){
      font-size: 22px;
      }
      @media(max-width:425px){
      br{
        display:none;
      }
      }
      @media(max-width:325px){
         font-size:27px;
      }
`;

