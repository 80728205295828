import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate} from 'react-router-dom';

// icons import

import mail_icon from "../../../assets/images/ContactPage/icons/mail.svg";
import location_icon from "../../../assets/images/ContactPage/icons/location.svg";
import call_icon from "../../../assets/images/ContactPage/icons/call.svg";
import {  QuickEnquiry } from "../../../axios/api";
import { ThreeDots } from "react-loader-spinner";

function MiddleSection() {
  const [formData, setformData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const [error, seterror] = useState("");
  const [done, setdone] = useState(false);
  const [loading, setloading] = useState(false);
  const [uploaded, setuploaded] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name } = e.target;

    setformData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const assignHandler = (e) => {
    var mobilePattern = /^[6789]\d{9}$/;
    var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    e.preventDefault();

    const form = new FormData();

    if (formData.name === "" || formData.name.length < 3) {
      seterror("Enter a valid name");
      setTimeout(() => {
        seterror("");
      }, 3000);
    } else if (formData.email === "" || !emailPattern.test(formData.email)) {
      seterror("Enter a valid email");
      setTimeout(() => {
        seterror("");
      }, 3000);
    } else if (formData.phone === "" || !mobilePattern.test(formData.phone)) {
      seterror("Enter a valid phone number");
      setTimeout(() => {
        seterror("");
      }, 3000);
    } else {
      Object.entries(formData).forEach((obj) => {
        form.append(obj[0], obj[1]);
      });
      setloading(true);
      axios.post(QuickEnquiry, form).then((response) => {
        const { StatusCode, data } = response.data.app_data;
        setloading(false);
        if (StatusCode === 6000) {
          // setdone(true);
          e.target.reset();
          setformData({
            name: "",
            phone: "",
            email: "",
          });
          // setTimeout(() => {
          //   setdone(false);
          // }, 3000);
          navigate('/thank-you',{
            state:{
              access:true,
            }
          })
        } else {
          e.target.reset();
        }
      });
    }
  };

  return (
    <Section>
      <AddressInfo>
        <OfficeInfo>
          <Heading>Office</Heading>
          <Item>
            <Title>
              <img loading='lazy'  src={location_icon} alt="" />
            </Title>
            <Content hidebr={true}>
              4th Floor, Classic Capitol, MRA-83, <br />
              Mavelipuram, Kakkanad, Cochin
            </Content>
          </Item>
        </OfficeInfo>
        <FactoryInfo>
          <Heading>Factory</Heading>
          <Item>
            <Title>
              <img loading='lazy'  src={location_icon} alt="" />
            </Title>
            <Content>Kinfra Industrial Park, Infopark Campus, Kakkanad</Content>
          </Item>
          <Item>
            <Title>
              <img loading='lazy'  src={mail_icon} alt="" />
            </Title>
            <Content>mail@classicinsides.com</Content>
          </Item>
          <Item>
            <Title>
              <img loading='lazy'  src={call_icon} alt="" />
            </Title>
            <Content>
              +91 9072341177 <br />
              +91 9072888511
            </Content>
          </Item>
        </FactoryInfo>
      </AddressInfo>
      <ContactForm>
        <Fields onSubmit={(e)=>assignHandler(e)}>
          {done && (
            <Success>Enquiry Send Successfully.We'll connect you soon.</Success>
          )}
          {error && <Error>{error}</Error>}
          <NameInput required onChange={(e)=>handleChange(e)} value={formData?.name}  name='name' placeholder="Name" />

          <PhoneInput required onChange={(e)=>handleChange(e)} value={formData?.phone}  name="phone" placeholder="Phone" />

          <EmailInput required onChange={(e)=>handleChange(e)} value={formData?.email}  name="email" placeholder="Email" />

          <MessageInput onChange={(e)=>handleChange(e)}  name="message" placeholder="Message" />

          <SubmitButton type="submit" className="flex">
          {loading ? (
                    <ThreeDots 
                    height="20" 
                    width="30" 
                    radius="3"
                    color="#ffffff" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}/>
                    
                  ) :"Send Now"}
          </SubmitButton>
        </Fields>
      </ContactForm>
    </Section>
  );
}

export default MiddleSection;

const Section = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 925px) {
    flex-direction: column;
  }
`;

const AddressInfo = styled.div`
  width: 50%;
  @media (max-width: 925px) {
    width: 70%;
  }
  @media (max-width: 580px) {
    width: 100%;
  }
`;

const ContactForm = styled.div`
  width: 50%;
  @media (max-width: 925px) {
    width: 85%;
    margin: 0 auto;
  }
  @media (max-width: 580px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const OfficeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const FactoryInfo = styled.div``;

const Title = styled.div`
  display: grid;
  place-content: center;
  padding: 0 10px;
  img {
    width: 25px;
    display: block;
  }
`;

const Content = styled.div`
  display: grid;
  place-content: center;
  font-family: "raleway_light";
  font-size: 16px;
  @media (max-width: 375px) {
    ${(props) =>
      props.hidebr &&
      `
            br{
            display: none;
            }
        `}
  }
`;

const Item = styled.div`
  display: flex;
  padding-left: 60px;
  margin: 10px 0;
  margin-bottom: 25px;
  @media (max-width: 580px) {
    padding-left: 45px;
  }
  @media (max-width: 425px) {
    padding-left: 20px;
  }
  @media (max-width: 375px) {
    padding-left: 10px;
  }
`;

const Heading = styled.h3`
  font-family: "raleway_bold";
  letter-spacing: 1px;
`;

const Fields = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  .file-label {
    color: #7f7f7f !important;
  }
`;
const NameInput = styled.input`
  margin: 5px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #939292;
  padding: 5px 10px;
  color: #6f6868;
  outline: none;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
  }
`;
const PhoneInput = styled.input`
  margin: 5px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #939292;
  padding: 5px 10px;
  color: #6f6868;
  outline: none;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
  }
`;
const EmailInput = styled.input`
  margin: 5px 0px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #939292;
  padding: 5px 10px;
  color: #6f6868;
  outline: none;
  @media (min-width: 1875px) {
    height: 32px;
  }
  &::placeholder {
    color: #7f7f7f;
  }
`;

const MessageInput = styled.textarea`
  margin: 5px 0px;
  height: 75px;
  background-color: transparent;
  border: 1px solid #939292;
  padding: 5px 10px;
  color: #6f6868;
  width: 96.5%;
  outline: none;
  @media (max-width: 425px) {
    width: 90%;
  }
  &::placeholder {
    color: #7f7f7f;
  }
`;

const SubmitButton = styled.button`
  width: 150px;
  background-color: transparent;
  margin: 10px 0px;
  padding: 15px 20px;
  color: #ffffff;
  border: 1px solid orange;
  font-family: "raleway_medium";
  background-color: var(--primary-cl);
  cursor: pointer;
`;


const Error = styled.p`
      width:100%;
      text-align: center;
      font-size:14px;
      padding: 0;
      color:red;
      margin:1px 0px;
      font-family:'raleway_regular';
`;

const Success = styled.p`
      width:100%;
      text-align: center;
      font-size:14px;
      padding: 0;
      color:var(--primary-cl);
      font-family:'raleway_regular';
`;