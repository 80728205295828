import React from 'react'
import { styled } from 'styled-components';

import bg from '../../../assets/images/serviceInner/bg.png';

function Banner({item}) {
  return (
    <Section>
      <Container>
          <img src={item?.service_banner} alt="" />
          <Box>
            <Title>
               {item?.title}
            </Title>
            <Body>
            Leave a Mark of Your Style with Our Design.
            </Body>
          </Box>
      </Container>
    </Section>
  )
}

export default Banner;

const Section = styled.div`
`;

const Container = styled.div`
 height:100%;
 width:100%;
 position: relative;
 img{
    width:100%;
    display:block;
    @media(max-width:580px){
    height:200px;
    }
 }
 &::after{
    position: absolute;
    inset: 0;
    background: radial-gradient(circle, #ffffff1e 0%, #ffffff13 35%, #0000004b 100%);
    content: '';
 }
`;

const Box = styled.div`
    position: absolute;
    width: 60%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding:90px 200px;
    z-index: 2;

    @media(min-width:2000px){
      padding:125px 300px;
    }
    @media(max-width:1400px){
      padding:75px 115px;
    }
    @media(max-width:1024px){
      padding:50px 50px;
    }
    @media(max-width:480px){
        padding:15px 25px;
    }
`;

const Title = styled.h1`
    font-family: 'wensley_bold';
    color: #fff;
    width: 100%;
    margin:0;
    padding: 0;
    font-size: 38px;
    margin-bottom:5px;

    @media(min-width:2000px){
      font-size: 50px;
    }

    @media(max-width:580px){
      font-size: 30px;
    }

`;

const Body = styled.p`
    font-family: 'raleway_light';
    color: #fff;
    width: 100%;
    margin:0;
    padding: 0;
    width: 50ch;
    font-size: 14px;
    line-height:16px;

    @media(min-width:2000px){
      width: 60ch;
      font-size: 19px;
      line-height: 22px;
    }

    @media(min-width:580px){
      font-size: 13px;
    }

    @media(max-width:480px){
      width:40ch;
    }
    @media(max-width:375px){
      font-size:12px;
    }
    @media(max-width:324px){
      width:32ch;
    }
`;
