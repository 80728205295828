import React from "react";
import { styled } from "styled-components";

import factoryImg from "../../../assets/images/other/factory.png";
import factoryImgMob from "../../../assets/images/other/mob/factory-img.png";
import { useNavigate } from "react-router-dom";

function FactoryInfo() {
  const navigate = useNavigate();
  return (
    <Section>
      <Container>
        <Breadcrumb className="first">Factory /</Breadcrumb>
        <InfoSection>
          <Breadcrumb className="second">Factory /</Breadcrumb>
          <ContentArea>
            <Heading>Innovating from within</Heading>
            <Paragraph>
              We are firm believers in producing and manufacturing needed
              materials domestically. As such, we have deployed a fully-equipped
              factory within Kinfra Export Promotion Industrial Park at Kakkanad
              in Kochi. Thus, our state-of-the art factory enables us to produce
              a diverse array of materials in-house to ensure top quality under
              the guidance of our dedicated personnel.
            </Paragraph>
          </ContentArea>
          <ReadMoreButton>
            <button onClick={() => navigate("/factory-info")}>Read More</button>
          </ReadMoreButton>
        </InfoSection>

        <Picture>
          <ImgDiv>
            <img loading="lazy" className="desk" src={factoryImg} alt="" />
            <img loading="lazy" className="mob" src={factoryImgMob} alt="" />
          </ImgDiv>
        </Picture>
      </Container>
    </Section>
  );
}

export default FactoryInfo;

const Section = styled.div`
  display: flex;
  margin: 0px 0px;
`;

const Container = styled.div`
  background-color: #efefef;
  width: 100%;
  margin: 0px auto;
  display: flex;
  padding: 0px 145px;

  .first {
    display: none;
  }

  .second {
    display: flex;
  }

  @media (min-width: 1875px) {
    height: 62vh;
  }
  @media (max-width: 1350px) {
    padding: 10px 80px;
  }
  @media (max-width: 968px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    .first {
      display: flex;
    }
    .second {
      display: none;
    }
  }
  @media (max-width: 580px) {
    padding: 10px 23px;
  }
`;

const InfoSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @media (min-width: 1875px) {
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0px 75px;
  }
  @media (max-width: 975px) {
    order: 3;
    width: 100%;
  }
`;

const Picture = styled.div`
  display: flex;
  width: 50%;
  @media (max-width: 1250px) and (min-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 968px) {
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    order: 2;
    width: 80%;
    margin:0 auto;
  }
  @media (max-width: 580px) {
    width: 100%;
  }
`;

const ImgDiv = styled.div`
  width: 92%;

  @media (min-width: 2000px) {
    width: 70%;
  }

  @media (min-width: 580px) {
    .mob {
      display: none;
    }
    .desk {
      display: block;
    }
  }
  @media (max-width: 580px) {
    width: 100%;
    .mob {
      display: block;
    }
    .desk {
      display: none;
    }
  }
  img {
    display: block;
    width: 100%;
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: start;
  justify-content: baseline;
  padding: 30px 10px;
  width: 75%;
  font-family: "raleway_light";
  font-size: 15px;
  @media (max-width: 768px) {
    order: 1;
  }
`;

const ContentArea = styled.div`
  width: 75%;
  display: flex;
  align-items: start;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h1`
  font-family: "wensley_light";
  margin-bottom: 10px;
  font-size: 31px;
  @media (max-width: 1350px) {
    width: 30ch;
  }
  @media (max-width: 1024px) {
    width: 23ch;
  }
  @media (max-width: 968px) {
    width: 20ch;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 580px) {
    font-size: 25px;
  }
  @media (max-width: 380px) {
    font-size: 24px;
  }
`;

const Paragraph = styled.p`
  /* font-family: 'raleway_regular'; */
  font-family: var(--ff);
  font-size: var(--para-fs);
  /* line-height: 22px; */
  line-height: var(--line-height);
  /* font-size: 14px; */
  font-weight: 400;
  width: 65ch;
  /* @media(min-width:1875px){
      font-size: 17px;
    } */
  @media (max-width: 1440px) {
    width: 55ch;
  }
  @media (max-width: 1350px) {
    width: 45ch;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ReadMoreButton = styled.div`
  display: flex;
  width: 75%;
  @media (max-width: 580px) {
    justify-content: center;
    width: 100%;
  }
  button {
    font-family: "raleway_semibold";
    border: 1px solid var(--primary-cl);
    padding: 15px 30px;
    background-color: #fff;
    float: left;
    margin: 10px 0px;
    transition: all 0.5s ease-in-out;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: var(--primary-cl);
    }
  }
`;
