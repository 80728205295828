import React, { useState } from 'react'
import { styled } from 'styled-components';

import bg from '../../../assets/images/projectspage/contactbanner/projects_contact.jpg'
import ContactUsModal from '../../modal/ContactUsModal';

function BottomContactUs() {
  const [isModal, setisModal] = useState(false);
  return (
    <>
    <Section bg={bg}>
        <ImgContainer>
            <img src={bg} alt="" />
            <Container>
            <Body>
            <Heading>
                The Ultimate standard in Interior Design & Decor
            </Heading>
            <SubHeading>
                Create an atmosphere of elegance that stands out to impress.
            </SubHeading>
            </Body>
            <ButtonDiv>
                <button
                onClick={()=>{
                    setisModal(true);
                }}
                >
                    CONTACT US
                </button>
            </ButtonDiv>
       </Container>
        </ImgContainer>
    </Section>
    <ContactUsModal isModal={isModal} setModal={setisModal} />
    </>
  )
}

export default BottomContactUs;


const Section = styled.div`
/* ${(props)=> props.bg && `
    background-image : url(${props.bg});
`} */
background-repeat:no-repeat;
background-size: cover;
width:100%;

`;

const Container = styled.div`
position: absolute;
top: 50%; /* Move the element 50% down from the top of its parent */
left: 50%; /* Move the element 50% from the left of its parent */
transform: translate(-50%, -50%);
color:#fff;
display: flex;
align-items: center;
justify-content: space-between;
width: 75%;

@media(max-width:1400px){
    width: 90%;
    transform: translate(0, -50%);
    left: 75px; 
}
@media(max-width:1024px){
    width: 90%;
}

@media(max-width:580px){
    width: 100%;
}

@media(max-width:768px){
    flex-direction: column;
    gap: 15px;
    left: 0;
}


`;

const ImgContainer = styled.div`
width:100%;
position:relative;
img{
    display: block;
    width: 100%;

    @media(max-width:1400px){
    height:250px;
    }
}
`;

const Body = styled.div`
width:60%;

@media(max-width:1400px){
    width:65%;
}

@media(max-width:768px){
    width:100%;
}
`;

const ButtonDiv = styled.div`
width:40%;
display: flex;
justify-content:center;
align-items:center;

@media(max-width:768px){
    width:100%;
}
button{
    border: 1px solid var(--primary-cl);
    padding: 22px 26px;
    background-color: transparent;
    color: #fff;
    font-family:'raleway_medium';
    
    @media(min-width:2000px){
        padding: 30px 60px;
    }

    @media(max-width:580px){
        padding: 15px 26px;

    }

    &:hover{
        color: var(--primary-cl);
    }
}
`;

const Heading = styled.h3`
    padding:0;
    margin:0;
    margin-bottom:5px;
    font-family: 'wensley_light';
    width: 100%;
    letter-spacing: 1px;
    font-size:23px;

    @media(min-width:2000px){
      font-size: 30px;
    }
    @media(max-width:1450px){
      font-size: 20px;
    }
    @media(max-width:768px){
       text-align:center;
    }
`;

const SubHeading = styled.p`
    padding:0;
    margin:0;
    font-family: 'raleway_thin';

    @media(min-width:2000px){
      font-size: 18px;
    }

    @media(max-width:1450px){
      font-size: 14px;
    }

    @media(max-width:768px){
       text-align:center;
    }

`;



