import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo/classic-logo.svg";
import whatsIcon from "../../assets/images/icons/whtsappIcon.svg";
import callIcon from "../../assets/images/icons/callIcon.svg";
import menuIcon from "../../assets/images/icons/menu.svg";
import chaticon from "../../assets/images/icons/chaticon.svg";
import chatclose from "../../assets/images/icons/chatclose.svg";
import chatactive from "../../assets/images/icons/chatactive.svg";
import chatdown from "../../assets/images/icons/chatdownarrow.svg";
import { HeaderContext } from "../../context/StoreContext";
import DropdownMenu from "./DropdownMenu";
import ContactUsModal from "../modal/ContactUsModal";

function Header() {
  const { state, dispatch } = useContext(HeaderContext);
  const [Chatactive, setChatactive] = useState(false);
  const [modal, setmodal] = useState(false);
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (!state.chat) {
      // bottom chat window open on onload
      // setTimeout(() => {
      //   setChatactive(true);
      //   dispatch({
      //     type: "TOGGLE_CHAT_SHOWED",
      //     payload: true,
      //   });
      // }, 3000);
    }
  }, []);

  const handleMenuToggle = () => {
    if (state.active === false) {
      document.querySelector("html").classList.add("model-enabled");
      document.querySelector("body").classList.add("model-enabled");
      document.querySelector("#root").classList.add("model-enabled");
    } else {
      document.querySelector("html").classList.remove("model-enabled");
      document.querySelector("body").classList.remove("model-enabled");
      document.querySelector("#root").classList.remove("model-enabled");
    }
    dispatch({
      type: "TOGGLE_MENU",
    });
  };

  const handleClick = (url) => {
    navigate(url);
  };

  return (
    <Section id="header" ref={sectionRef}>
      <Container className="container">
        <LogoSection>
          <ImgDiv onClick={() => handleClick("/")}>
            <img src={logo} alt="" />
          </ImgDiv>
        </LogoSection>
        <NavBarSection>
          <SquareContainer>
            <TalkButton href="tel:+919072341177">
              Talk with an Expert
            </TalkButton>
            <MenuIcon onClick={handleMenuToggle}>
              <IconContainer active={state.active}>
                <img src={menuIcon} alt="" />
              </IconContainer>
            </MenuIcon>
          </SquareContainer>
        </NavBarSection>
      </Container>
      <FloatingIcons>
        <WhatsappIcon href="https://wa.me/+919072888511" target="_blank">
          <img src={whatsIcon} alt="" />
        </WhatsappIcon>
        <CallIcon href="tel:+919072888511" target="_blank">
          <img src={callIcon} alt="" />
        </CallIcon>
      </FloatingIcons>

      {/* <ChatConatiner className={Chatactive && "active"}>
        <ChatPopUp className={Chatactive && "active"}>
          <CloseBtn onClick={() => setChatactive(false)}>
            <img src={chatclose} alt="" />
          </CloseBtn>
          <Img>
            <img src={chatactive} alt="" />
          </Img>
          <Info>
            Thank you for visiting Classic Insides. For more details,
            <a onClick={() => setmodal(true)}>talk with our experts</a>
          </Info>
        </ChatPopUp>
        <Icon onClick={() => setChatactive(!Chatactive)}>
          <img src={chaticon} alt="" />
        </Icon>
      </ChatConatiner> */}

      {state.active && <DropdownMenu />}
      <ContactUsModal isModal={modal} setModal={setmodal} />
    </Section>
  );
}

export default Header;

const Section = styled.div`
  display: flex;
  /* padding:25px; */
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 250000;
  background-color: #1d1c1a;

  @media (min-width: 2000px) {
    height: 130px;
  }
  @media (max-width: 1999px) {
    height: 100px;
  }
  @media (max-width: 1750px) {
    height: 75px;
  }

  @media (max-width: 1325px) {
    height: 85px;
  }

  @media (max-width: 800px) {
    height: 65px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: -3px auto;
  @media (max-width: 1440px) {
    width: 85%;
  }
`;

const LogoSection = styled.div`
  width: 50%;
`;
const ImgDiv = styled.div`
  width: 100px;
  position: absolute;
  top: 12px;
  @media (min-width: 1750px) {
    width: 130px;
  }
  cursor: pointer;
  /* @media(max-width:1439px){
       width:9%;
    } */
  @media (max-width: 1000px) {
    width: 13%;
  }
  @media (max-width: 800px) {
    width: 90px;
  }
  @media (max-width: 580px) {
    width: 85px;
  }
  img {
    display: block;
    width: 100%;
  }
`;

const NavBarSection = styled.div`
  width: 201px;
  display: flex;
  align-items: center;
  justify-content: end;

  @media (min-width: 1865px) {
    width: 275px;
  }
`;

const MenuIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  width: fit-content;
  width: 27px;
  opacity: 50%;
  cursor: pointer;
  
  @media (min-width: 1900px) {
    width: 50px;
  }
`;
const SquareContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: end;
  width: 100%;
  @media (min-width: 2000px) {
    height: 130px;
  }
  @media (max-width: 1999px) {
    height: 100px;
  }
  @media (max-width: 1750px) {
    height: 75px;
  }
  @media (max-width: 1325px) {
    height: 85px;
  }

  @media (max-width: 800px) {
    height: 65px;
  }

  @media (max-width: 580px) {
    justify-content: end;
  }
`;

const TalkButton = styled.a`
  padding: 10px 20px;
  border: none;
  background-color: var(--primary-cl);
  font-family: "raleway_light";
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  @media (max-width: 580px) {
    display: none;
  }
  @media (min-width: 1900px) {
    font-size: 18px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img {
    width: 90%;
    display: block;
    transition: transform 600ms linear;
    ${(props) =>
      props.active &&
      `
              transform: rotate(270deg);
            `}
  }
`;

const FloatingIcons = styled.div`
  display: flex;
  position: fixed;
  right: 30px;
  top: 40%;
  padding: 10px;
  gap: 15px;
  z-index: 250;
  flex-direction: column;

  @media (max-width: 950px) {
    right: 25px;
  }
  @media (max-width: 580px) {
    right: 5px;
    top: 62%;
    padding: 5px;
  }
`;

const WhatsappIcon = styled.a`
  display: block;
  cursor: pointer;
  img {
    object-fit: contain;
    width: 50px;
    @media (max-width: 580px) {
      width: 45px;
    }
  }
`;

const CallIcon = styled.a`
  display: block;
  cursor: pointer;
  img {
    object-fit: contain;
    width: 50px;
    @media (max-width: 580px) {
      width: 45px;
    }
  }
`;

const ChatConatiner = styled.div`
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 250;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0px;

  @media (max-width: 580px) {
    right: 10px;
    bottom: 30px;
  }

  &.active{
    gap:15px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    object-fit: contain;
    width: 50px;
    @media (max-width: 580px) {
      width: 45px;
    }
  }
`;

const ChatPopUp = styled.div`
  padding: 0px;
  position: relative;
  background-color: #f2f2f2;
  gap: 8px;
  border-radius: 10px;
  opacity: 0;
  transform: scale(0, 0);
  transition: transform 0.25s linear, opacity 0.25s linear;
  display: flex;

  div{
    display: none;
  }
  
  &::after {
    position: absolute;
    content: "";
    background-image: url(${chatdown});
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -25px;
    right: 10px;
    height: 30px;
    width: 30px;
  }

  &.active {
    opacity: 1;
    transform: scale(1, 1);
    padding: 18px;

    div{
      display: block;
    }
  }

  @media (max-width: 400px) {
    padding: 15px;
  }
  @media (max-width: 380px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 20px;
    width: 90%;
  }
  @media (max-width: 350px) {
    right: 5px;
  }
  @media (max-width: 330px) {
    right: 0px;
  }
`;

const Img = styled.div`
  img {
    display: block;
    width: 52px;
  }
`;

const Info = styled.div`
  padding: 10px;
  width: 30ch;
  color: #000000;
  font-family: "raleway_regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  background-color: #fff;
  border-radius: 0px 11.941px 11.941px 11.941px;

  a {
    color: #2e79d2;
    text-decoration-line: underline;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: -13px;
  right: -10px;
`;
