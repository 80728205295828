import React, {  useEffect,useState } from "react";
import { styled } from "styled-components";
import axios  from 'axios';

import EastIcon from '@mui/icons-material/East';
import {useNavigate}  from 'react-router-dom'

import Slider from "react-slick";
import AutoSlideImage from "./AutoSlideImage";
import { ListProjectImagesApi } from "../../axios/api";


function CustomProjectSlider() {
  const [projectsData, setprojectsData] = useState([]);
  const [intervalId, setintervalId] = useState(null);
  const [width, setwidth] = useState(0);
  const [currentData, setcurrentData] = useState({
    current: 6,
    postionArray: [0,1,2, 3,4,5],
    projectsCount: 0,
  });

  const [state, setstate] = useState(0);
  const [showAnimation, setshowAnimation] = useState(false);
  const [firstEffect, setfirstEffect] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    const widthValue = window.innerWidth ;
    setwidth(widthValue)
   
    axios.get(ListProjectImagesApi).then((response)=>{
      const { data , StatusCode} = response.data.app_data;

      if(StatusCode === 6000){
        setprojectsData(data)

        const numArray = [];
        for (let i = 0; i < currentData.current; i++) {
        numArray.push(i);
        }

        setcurrentData((prev) => ({
          ...prev,
          postionArray: numArray,
          projectsCount: data.length,
        }));

      }else{
        setprojectsData([])
      }
    })

    
    setfirstEffect(true);
    const id = setInterval(() => {
      controllAutomaticSliderMove();
      setshowAnimation(true);
      setTimeout(()=>{
        setshowAnimation(false);
      },2500)
    }, 3000);

    setintervalId(id);

    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  },[]);


  useEffect(() => {
    if (firstEffect) {
      let currentPosition = currentData.current - 6;
      let projectCount = currentData.projectsCount;

      
      const numArray = [];

      for (let i = 0; i < 6; i++) {
        if (currentPosition <= projectCount - 1) {
          numArray.push(currentPosition);
          currentPosition++;
        } else {
          numArray.push(0);
          currentPosition = 1;
        }
      }


      setcurrentData((prev) => ({
        ...prev,
        postionArray: numArray,
      }));

      if (intervalId != null) {
        clearTimeout(intervalId);
      }

      const id = setInterval(() => {
        controllAutomaticSliderMove();
        setshowAnimation(true);
        setTimeout(()=>{
        setshowAnimation(false);
        },2500)
      }, 3000);
      setintervalId(id);

      return () => {
        if (intervalId != null) {
          clearInterval(intervalId);
        }
      };
    }
  }, [state]);

  const controllAutomaticSliderMove = () => {
    
    setcurrentData((prev) => {
      if(prev.current===prev.projectsCount+6){
        return { ...prev, current: 6 };
      }else{
        return { ...prev, current: prev.current + 1 };
      }
      
    });
    setstate((prev) => {
      let data = prev+1
      return data;;
    });
  };


  const settings = {
    arrows:false, 
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear"
  };

  return (
    <Section>
      <Container>
        <BreadCrumb>Projects /</BreadCrumb>
        {width<580?(
          <Slider {...settings} className="mobileSlider">
            {
              projectsData.map((obj)=>{
                return (
                  <MobSlider className="innerArea">
                  <Wrapper>
                  <ProjectHighlight>
                      <AutoSlideImage assets={obj?.project_images}/>
                  </ProjectHighlight>
                  <SideBar>
                    <SmallPreview>
                        <img loading='lazy'  src={obj?.project_images[1]?.resized_image} alt="" />
                    </SmallPreview>
                    <SmallPreview>
                        <img loading='lazy'  src={obj?.project_images[2]?.resized_image} alt="" />
                    </SmallPreview>
                    <SmallPreview last={1}>
                        <img loading='lazy'  src={obj?.project_images[3]?.resized_image} alt="" />
                        <p onClick={()=>navigate(`/project/${obj?.slug}`)}>
                          View <br /> More >>
                        </p>
                    </SmallPreview>
                  </SideBar>
                  </Wrapper>
                  <ClientInfo>
                    <p>{obj?.title}</p>
                  </ClientInfo>
                </MobSlider>
                )
              })
            }
          </Slider>
        ):(
        <>
          <SliderArea>
          <OneProject onClick={()=>{navigate(`/project/${projectsData[currentData.postionArray[0]]?.slug}`)}} className="one">
            <ImgDiv img={projectsData[currentData.postionArray[0]]?.project_images[0]?.image} />
            <Title show={showAnimation===true?'true':'false'} >{projectsData[currentData.postionArray[0]]?.title}</Title>
          </OneProject>
          <OneProject  className="two" onClick={()=>navigate(`/project/${projectsData[currentData.postionArray[1]]?.slug}`)} >
            <Title show={showAnimation===true?'true':'false'}>{projectsData[currentData.postionArray[1]]?.title}</Title>
            <ImgDiv img={projectsData[currentData.postionArray[1]]?.project_images[0]?.image} />
          </OneProject>
          <OneProject className="three" onClick={()=>navigate(`/project/${projectsData[currentData.postionArray[2]]?.slug}`)} >
            <ImgDiv img={projectsData[currentData.postionArray[2]]?.project_images[0]?.image} />
            <Title show={showAnimation===true?'true':'false'}>{projectsData[currentData.postionArray[2]]?.title}</Title>
          </OneProject>
          <OneProject className="four" onClick={()=>navigate(`/project/${projectsData[currentData.postionArray[3]]?.slug}`)} >
            <Title show={showAnimation===true?'true':'false'}>{projectsData[currentData.postionArray[3]]?.title}</Title>
            <ImgDiv img={projectsData[currentData.postionArray[3]]?.project_images[0]?.image} />
          </OneProject>
          <OneProject className="five" onClick={()=>navigate(`/project/${projectsData[currentData.postionArray[4]]?.slug}`)} >
            <ImgDiv img={projectsData[currentData.postionArray[4]]?.project_images[0]?.image} />
            <Title show={showAnimation===true?'true':'false'}>{projectsData[currentData.postionArray[4]]?.title}</Title>
          </OneProject>
          <OneProject  className="six" onClick={()=>navigate(`/project/${projectsData[currentData.postionArray[5]]?.slug}`)} >
            <Title show={showAnimation===true?'true':'false'}>{projectsData[currentData.postionArray[5]]?.title}</Title>
            <ImgDiv img={projectsData[currentData.postionArray[5]]?.project_images[0]?.image} />
          </OneProject>
          </SliderArea>
          <ViewMore> <p onClick={()=>navigate('/projects')}>View More <EastIcon/></p></ViewMore>
        </>
        )}
      </Container>
    </Section>
  );
}

export default CustomProjectSlider;

const Section = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 25px 0px;
  @media(max-width:580px){
    margin-bottom: 35px;
  }
`;

const Container = styled.div`
  width: 87%;
  display: flex;
  flex-direction: column;
  /* .mobileSlider{
    .slick-next,.slick-prev{
      display: none !important;
    }
  } */
  @media(max-width:1440px){
    width:93%;
  }
  @media(max-width:1875px){
    .five,.six{
      display: none;
    }
  }
  @media(max-width:1325px){
    .five,.six,.four{
      display: none;
    }
  }
   @media(max-width:968px){
    width:95%;
  }
  @media(max-width:580px){
    .five,.six,.four,.three{
      display: none;
    }
  }
`;

const SliderArea = styled.div`
  display: flex;
  div {
    margin: 0px 3px;
  }
  .fade-animation{
    animation : fade 2.5s linear;
  }

  @keyframes fade{
    from{
      opacity: 0;
    }
    75%{
      opacity: .7;
    }
    to{
      opacity: 1;
    }
  }
`;

const OneProject = styled.div`
  display: flex;
  flex-direction: column;
  height: 475px;
  width: 350px;
  cursor: pointer;
  @media(max-width:580px){
    height: 330px;
  }
`;

const ImgDiv = styled.div`
  width: 100%;
  ${(props) => props.img && `background:url(${props.img}) 50% 50% no-repeat`};
  height: 100%;
  background-size: cover;
  transition: background-image 1s ease-out;
`;

const Title = styled.div`
  display: flex;
  font-size: 15px;
  font-family: "raleway_regular";
  padding: 10px 2px;
  opacity: ${(props) => (props.show==='true' ? 1 : 0)};
  transition: opacity 0.7s ease-in-out;
  @media(max-width:580px){
    font-size: 13px;
    line-height: 18px;
  }
`;

const BreadCrumb = styled.div`
  display: flex;
  align-items: start;
  justify-content: baseline;
  padding: 30px 10px;
  width: 75%;
  font-family: "raleway_light";
  font-size: 15px;
`;

const ViewMore = styled.div`
  display: flex;
  margin: 10px -35px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-family: 'raleway_light';
    cursor: pointer;
  }
`;


const MobSlider = styled.div`
    display: flex !important;
    /* gap:10px; */
    flex-direction: column;
`;

const SideBar = styled.div`
    display: flex;
    width: 35%;
    flex-direction:column;
    gap:10px;
    height: 100%;
    align-items:center;
`;

const ProjectHighlight = styled.div`
    display: flex;
    width: 65%;
    flex-direction: column;
    height: 100%;
    img{
      width:100%;
      display: none;
    }
`;


 

const ClientInfo = styled.div`
   height: 10%;
   p{
    width:100%;
    text-align: center;
    font-size:14px;
    font-family:"raleway_regular";
    margin-top:0;
   }
`;

const SmallPreview = styled.div`
width: 83%;
height: 30%;
position: relative;
cursor: pointer;
display: flex;
justify-content: center;

${(props)=>props.last && `
  &::after{
  content:'';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-cl);
  opacity: .7;
};
`}


p{
  position: absolute;
    top: 13%;
    font-size: 17px;
    color: #fff;
    z-index: 2;
    padding: 0 22px;
    line-height: 18px;
    text-align:center;
    cursor:pointer;

    @media(max-width:360px){
      top: 0;
      text-align: center;
    }
}

img{
  display: block;
  width: 100%;
  height:100%;
}

`;
const Wrapper = styled.div`
  display: flex;
   height: 375px;
`;



