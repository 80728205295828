import React, { useContext } from 'react'
import { styled } from 'styled-components';

import closeIcon from '../../assets/images/icons/close-icon.svg'
import { HeaderContext } from '../../context/StoreContext';

import {useNavigate } from 'react-router-dom';

import classicLogo from '../../assets/images/logo/classic-home-logo.png';

function DropdownMenu() {
    const {state,dispatch} = useContext(HeaderContext);
    const navigate = useNavigate();

   const handleMenuToggle = ()=>{
      if (state.active === false) {
        document.querySelector("html").classList.add("model-enabled");
        document.querySelector("body").classList.add("model-enabled");
        document.querySelector("#root").classList.add("model-enabled");
        
      } else {
        document.querySelector("html").classList.remove("model-enabled");
        document.querySelector("body").classList.remove("model-enabled");
        document.querySelector("#root").classList.remove("model-enabled");
      }
        dispatch({
          type: 'CLOSE_MENU'
        });
    };
    const handleClick = (path)=>{
        navigate(path);
        handleMenuToggle();
    }
  return (
    <Section>
        <Container>
            <CloseButton>
                <Img onClick={handleMenuToggle}>
                   <img src={closeIcon} alt="" />
                </Img>
            </CloseButton>
            <MenuList>
            {/* <MenuItem>
             <TalkButton>
                Talk with an Expert
             </TalkButton>
             </MenuItem> */}
             <MenuItem onClick={()=>handleClick('/')}>
             <p>Home</p>
             </MenuItem>

             <MenuItem onClick={()=>handleClick('/about')}>
             <p>About</p>
             </MenuItem>

             <MenuItem onClick={()=>handleClick('/factory-info')}>
             <p> Factory</p>
             </MenuItem>

             <MenuItem onClick={()=>handleClick('/projects')}>
             <p>Projects</p>
             </MenuItem>
             <MenuItem onClick={()=>handleClick('/services')}>
             <p>Services</p>
             </MenuItem>

             <MenuItem onClick={()=>handleClick('/contact')}>
             <p>Contact</p>
             </MenuItem>

             <MenuItem onClick={()=>handleClick('/blogs')}>
             <p>Blogs</p>
             </MenuItem>

            </MenuList>
            <TalkButton href='tel:+919072341177'>
                Talk with an Expert
            </TalkButton>
            {/* <ClassicLogo href='https://www.classichomes.in/' target='_blank'>
                <Logo  src={classicLogo}  />
            </ClassicLogo> */}
        </Container>
    </Section>
  )
}

export default DropdownMenu;


const Section = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content:end;
  top: 80px;
  z-index: 2000;
  cursor: pointer;
  @media(min-width:2000px){
        top: 130px;
  }
  @media(max-width:1999px){
        top: 100px;
   }
   @media(max-width:1750px){
        top: 75px;
    }
    /* @media(max-width:1325px){
        top: 85px;
    } */

    @media(max-width:800px){
        top: 65px;
    }

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 700px;
    background-color:var(--secondary-cl);
    width:calc(201px + 10%);
    opacity:80%;
    padding:20px;
    overflow-y: scroll;
    box-sizing:border-box;
    user-select: none;
    @media(min-width:1800px){
        height: 85vh;
    }
    @media(max-width:1440px){
        width:calc(201px + 7.5%);
    }
   
    @media(min-width:1865px){
        width:calc(275px + 10%);
    }
    @media(max-width:1024px){
        height: 82vh;
        min-height: 516px;
    }
    @media(max-width:580px){
        padding-top: 0;
        height: 90vh;
    }
    
    `;

const CloseButton = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    height: 70px;
    width: 20%;
    padding: 10px;
    width:80%;
    margin:0px auto;
    `


const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    `;

const MenuItem = styled.div`
    display: flex;
    color: #fff;
    font-size: 14px;
    font-family: 'raleway_regular';
    border:none;
    border-top: 2px solid #fff;
    margin:15px 0px;
    height: 60px;
    padding:3px 2px;
    width: 65%;
    margin:0px auto;
    transition: all 2s ease;

    &:hover{
        border-top: 2px solid orange;
    }
    &:hover p{
        animation: slow-move 1.3s normal;
        text-align: end;
        width: 100%;
        @keyframes slow-move{
            0%{
                width:0%
            }
            100%{
                width:100%
            }
        }
    }
    p{
        margin:0;
        text-align:end;
        transition: width 1s ease;
    }
`;

const Img = styled.div`
    display: block;
    width:25px;
    background-color:var(--secondary-cl);
    opacity: 70%;
    img{
        width: 100%;
        display: block;
    }
    `


const ClassicLogo = styled.a`
    width: 65%;
    margin: 0 auto;
    border: 2px solid #fff;
    height: 45px;
    display: flex;
    align-items:center;
    justify-content:center;
    text-decoration:none;

    @media(max-width:580px){
        height: 50px;
        padding:7px;
        width: 80%;
    }
`;


const Logo = styled.img`
      width:100px;
      height:100%;
      
      @media(max-width:580px){
        width:100px;
      }

`;

const TalkButton = styled.a`
    padding: 10px 20px;
    border: none;
    background-color: var(--primary-cl);
    font-family: 'raleway_light';
    color: #fff;
    display: none;
    margin: 0px auto 15px auto;
    font-size: 14px;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    width: 65%;
    @media(max-width:580px){
     width: 80%;
     padding: 12px 12px;
     display: flex;
    }
    `
