import React from 'react'
import { styled } from 'styled-components';

function TopContent() {
  return (
    <Section>
        <BreadcrumbArea>
          <Title>
            Projects /
          </Title>
          <Path>
            Home/Projects
          </Path>
        </BreadcrumbArea>
        <Content>
            <HeadingArea>
            Unveiling Our Masterpieces
            </HeadingArea>
            <ParaArea>
            We proudly display a curated selection of the remarkable work undertaken by Classic Insides.
            This is where our dedication to crafting extraordinary interior spaces comes to life.
            Explore our portfolio to witness the artistry, innovation, and attention to detail that define each project we undertake.
            From residential transformations to commercial marvels, our portfolio is a testament to our commitment to excellence in interior design.
            </ParaArea>
        </Content>
    </Section>
  )
}

export default TopContent;


const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0 0px;
    margin: 50px auto 20px;
    @media(max-width:480px){
      margin: 20px auto 20px;
    }
`;

const BreadcrumbArea = styled.div`
    display: flex;
    justify-content:space-between;
`;

const Title = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Path = styled.p`
    font-family: 'raleway_thin';
    font-size: 14px;
`;

const Content = styled.div`
    display: flex;

    @media(max-width:1024px){
     flex-direction: column;
    }
`;

const HeadingArea = styled.h1`
    width: 40%;
    display: flex;
    align-items:center;
    font-family:'wensley_bold';
    color: #000;
    opacity: 80%;
    font-size:68px;

    @media(min-width:2000px){
      font-size: 90px;
    }
    @media(max-width:1400px){
     font-size:63px;
    }
    @media(max-width:1200px){
     font-size:50px;
    }
    @media(max-width:1024px){
     width: 100%;
     margin: 25px 0px 10px;
    }

    @media(max-width:580px){
      font-size: 34px;
    }
    @media(max-width:480px){
      font-size:25px;
    }
    @media(max-width:375px){
      font-size:44px;
    }
    @media(max-width:324px){
      font-size:38px;
    }

`;

const ParaArea = styled.div`
    width: 60%;
    display: flex;
    align-items:center;
    /* font-family: 'raleway_medium'; */
    /* font-size: 13px; */
    font-family: var(--ff);
    font-size: var(--para-fs);
    /* line-height: 26px; */
    line-height: var(--line-height);

    /* @media(min-width:2000px){
      font-size: 20px;
      line-height: 35px;
    } */
    @media(max-width:1400px){
       /* font-size: 12px; */
       /* line-height: 20px; */
       text-align:left;
    }
    @media(max-width:1024px){
     width: 100%;
     /* line-height: 17px; */
    }
    /* @media(min-width:580px){
      font-size: 13px;
    } */
`;

