import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import close from '../../assets/images/icons/close.svg';
import ReactPlayer from "react-player";



function TestimonialModal({ isModal, setModal, video }) {

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
      <CloseIcon src={close} onClick={() => setModal(false)}/>
        <Containers>
          <VideoContainer>
            {
                video?.video && 
                // <video preload playsInline muted  controls loop  src={video?.video}></video>
                <ReactPlayer url={video?.video} playing={true}
                playsinline muted controls loop
                />
            }
            
          </VideoContainer>
          <NameInfo>
              {video?.Name}
          </NameInfo>
        </Containers>
      </Modal>
    </Container>
  );
}
export default TestimonialModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background:var(--lightgrey);
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  /* @media all and (max-width: 1380px) {
    left: 58%;
  } */
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 350px;
  }
  @media all and (max-width: 360px) {
    width: 330px;
  }
`;
const Containers = styled.div`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap:15px;

  @media all and (max-width: 1300px) {
    padding: 30px;
  }

  @media all and (max-width: 580px) {
    padding: 20px;
  }

`;

const VideoContainer = styled.div`
      width:100%;
      margin: 0 auto;
      height: 70%;
      video{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    div{
        width: 100% !important;
        height: 100% !important;
    }

    @media(max-width:580px){
      div{
        width: 100% !important;
        height: 100% !important;
      }
    }
`;

const NameInfo = styled.p`
      font-size: 21px;
      text-align: center;
      font-family: 'raleway_medium';
`;

const Head = styled.div`
      font-family: 'raleway_medium';
      padding: 25px;
      text-align: center;
      box-sizing: border-box;
      width: 50%;
      font-size: 25px;
      margin: 0 auto;
      border-bottom: 2px solid var(--grey-white-cl);
`;

const CloseIcon = styled.img`
      width:20px;
      position: absolute;
      right:0px;
      top:0px;
      border: 1px solid black;
      border-radius: 50%;
      z-index: 200;
`;

