import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components';
import AssetSlider from './slider/AssetSlider';
import BookFactoryVisitModal from '../../modal/BookFactoryVisitModal';
import BookIcon1 from '../../../assets/images/icons/booking-icon-1.svg';
import axios from 'axios';
import { FactoryMachineListApi } from '../../../axios/api';

function FactoryInfo({data}) {
    const [isModal, setisModal] = useState(false);
    const [factoryData, setfactoryData] = useState([]);
    
    useEffect(() => {
      axios.get(FactoryMachineListApi).then((response) =>{
        const { data , StatusCode } = response.data.app_data;

        if(StatusCode === 6000){
          setfactoryData(data);
        }
      })
    },[])



  return (
    <Section>
        <Container>
            <BookBtn onClick={()=>setisModal(true)}>
              <Icon className='icon' src={BookIcon1} />
              <Text className='text'>
                Book Factory Visit
              </Text>
            </BookBtn>
            <About>
              <Title>
               {data?.machinery_title}
              </Title>
              <Body>
              {data?.machinery_content}
              </Body>
            </About>
            <MachineInfos>
              {factoryData.map((i,indx)=>{
               if(indx % 2 === 0) {
                  return (
                    <MachineInfo r={"s"}>
                    <Assets>
                      <AssetSlider assets={i?.factory_asset}/>
                    </Assets>
                    <Content>
                      <Div>
                       <ConetntTitle>
                       {i.machine_name}
                       </ConetntTitle>
                       <Para>
                       {i.machine_description}
                       </Para>
                       </Div>
                    </Content>
                    </MachineInfo>
                  )
               }else{
                  return (
                     <MachineInfo  r={"r"}>
                    <Assets>
                      <AssetSlider assets={i?.factory_asset}/>
                    </Assets>
                    <Content>
                    <Div>
                       <ConetntTitle>
                       {i.machine_name}
                       </ConetntTitle>
                       <Para>
                       {i.machine_description}
                       </Para>
                    </Div>
                    </Content>
                </MachineInfo>
                  )
               }
              })}
            </MachineInfos>
        </Container>
        <BookFactoryVisitModal isModal={isModal} setModal={setisModal} />
    </Section>
  )
}

export default FactoryInfo;

const Section = styled.div`
width: 100%;
`;

const Container = styled.div`
width: 80%;
margin: 0 auto;

@media(max-width:1375px){
    width:90%;
}
@media(max-width:968px){
    width:95%;
}

@media(max-width:580px){
    width:98%;
}

`;

const  About = styled.div`
 padding: 50px;
 background-color: var(--grey-white-cl);
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;

 @media(min-width:1900px){
    padding:100px;
  }

  @media(max-width:1375px){
    padding:20px;
  }
  @media(max-width:800px){
    padding:40px;
  }

  @media(max-width:580px){
    padding:40px 15px;
  }
`;

const Title = styled.h2`
text-align: center;
font-family: 'wensley_regular';
margin-bottom: 1px;
font-weight: 550 !important;
@media(min-width:1900px){
    font-size: 40px;
}
`;

const Body = styled.p`
text-align: center;
font-family: var(--ff);
font-size: var(--para-fs);
line-height: var(--line-height);
/* font-family: 'raleway_medium'; */
/* font-size: 14px; */
/* line-height: 20px; */

/* @media(min-width:1900px){
    font-size: 22px;
    line-height: 26px; 
} */

@media(min-width:580px){
  text-align: left;
}

`;

const  MachineInfos = styled.div`
display: flex;
flex-direction: column;
`;

const MachineInfo = styled.div`
display: flex;
${(props)=>props.r === 'r'? (`flex-direction: row-reverse`):(`flex-direction: row`)};
height:425px;

@media(min-width:1900px){
   height:700px;
}

@media(max-width:1375px){
    height:385px;
}
@media(max-width:768px){
    flex-direction:column;
    height:auto;
}
`;

const Assets = styled.div`
width: 50%;
background-color:var(--grey-white-cl);
@media(max-width:768px){
  width:100%;
  margin: 0 auto;
  height:450px;
}

@media(max-width:580px){
  height:250px;
}

`;


const Content = styled.div`
 padding: 75px 0px;
 width : 50%;
 display: flex;
 align-items:center;
 justify-content:center;

 @media(max-width:768px){
    width:90%;
    margin: 0 auto;
    padding: 40px 0px;
  }
  @media(max-width:580px){
    padding: 20px 0px;
  }
 `;

 const Div = styled.div`
 display:flex;
 align-items:start;
 justify-content:center;
 flex-direction:column;
 width: 80%;
 margin: 0 auto;

 @media(max-width:1375px){
    width:85%;
  }

   @media(max-width:800px){
    width:100%;
  }

 `;

const ConetntTitle = styled.h2`
text-align: center;
font-family: 'wensley_regular';
margin-bottom: 1px;
font-weight: 550 !important;
width: 100%;
text-align:start;
text-transform:uppercase;

@media(min-width:1900px){
    font-size:35px;
}
`;

const Para = styled.p`
    text-align: left;
    /* font-family: 'raleway_medium'; */
    /* font-size: 14px; */
    font-family: var(--ff);
    font-size: var(--para-fs);
    /* line-height: 20px; */
    line-height: var(--line-height);
    width: 77%;
    margin: 25px auto 0px;
    width: 100%;
    text-align:left;

    /* @media(min-width:1900px){
        font-size: 21px;
        line-height: 30px;
    } */

    /* @media(max-width:1375px){
        font-size:13px;
    } */
   
`;

const BookBtn = styled.button`
      color:#ffff;
      font-size: 18px;
      font-family: 'raleway_medium';
      width:60px;
      height:60px;
      margin: 10px auto;
      padding: 15px 0;
      display: flex;
      align-items:center;
      justify-content:center;
      border:3px solid var(--primary-cl);
      cursor: pointer;
      border-radius:50%;
      background-color:var(--primary-cl);
      position: fixed;
      bottom: unset;
      top:73vh;
      right:30px;
      transition: all .2s linear;
      z-index:200;
      .text{
        display:none;
      }

      &:hover{
        width:245px;
        border-radius: 10px;
        /* .icon{
          display: none;
        } */
        .text{
          display: block;
        }
      }

      @media(max-width:768px){
        right:25px;
      }
      @media(max-width:580px){
        right:10px;
        height: 50px;
        width: 50px;
      }
`;

const Icon  = styled.img`
    width:25px;
    margin:0px 3px;
`;

const Text = styled.p`
    color:#ffff;
    font-size: 16px;
    font-family: 'raleway_medium';
    text-align: center;
`;


  
