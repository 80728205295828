import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { ListBlogsApi } from "../../../axios/api";
import { useNavigate } from "react-router-dom";

function BlogList() {
  const [page, setpage] = useState(1);
  const [blogs, setblogs] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(ListBlogsApi,{
        params: {
          page: page,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          setblogs(data);
        }
      });
  }, [page]);

  const handleLoadMore = () => {
    if(page <= parseInt(blogs?.num_pages)){
      setpage((prev)=>prev + 1);
    }
};

  return (
    <Section>
      {blogs?.results?.map((obj) => {
        return (
          <Card>
            <Top>
              <img src={obj.blog_image} alt="" />
            </Top>
            <Bottom>
              <Content>
                <Heading>{obj.title}</Heading>
                <Para dangerouslySetInnerHTML={{__html:obj.body?.slice(0,250) + ' ...'}}/>
              </Content>
              <DateInfo>
                <Date>{obj.date}</Date>
                <ReadMore onClick={()=>navigate('/blog/'+ obj.slug)}>Read More</ReadMore>
              </DateInfo>
            </Bottom>
          </Card>
        );
      })}

      {page < blogs?.num_pages && (
        <LoadButton>
          <button onClick={handleLoadMore}>Load More</button>
        </LoadButton>
      )}
    </Section>
  );
}

export default BlogList;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: 25px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  aspect-ratio: 16/9;

  @media (max-width: 1125px) {
    width: 49%;
  }

  @media (max-width: 675px) {
    width: 99%;
  }
`;

const Top = styled.div`
  img {
    width: 100%;
    display: block;
  }
`;

const Bottom = styled.div`
  padding: 30px;
  background-color: var(--lightgrey);
  box-sizing: border-box;

  @media (max-width: 425px) {
    padding: 20px;
  }
`;

const Content = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #938e8e;
`;

const DateInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.h5`
  font-family: "raleway_semibold";
  font-size: 22px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-weight: 500;
`;

const Para = styled.p`
  margin: 0;
  padding: 0;
  font-family: "raleway_regular";
  font-size: 14px;
  text-align: justify;
  line-height: 22px;
`;

const Date = styled.p`
  font-family: "raleway_regular";
`;

const ReadMore = styled.p`
  font-family: "raleway_regular";
  font-size: 14px;
  cursor: pointer;
`;

const LoadButton = styled.div`
  width: 100%;
  margin: 20px 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1400px) {
    margin: 40px 0px 25px;
  }

  button {
    border: 1px solid var(--primary-cl);
    background-color: white;
    padding: 12px 26px;
    font-size: 14px;
    font-family: "raleway_medium";

    &:hover {
      background-color: var(--primary-cl);
      color: #fff;
    }
  }
`;
