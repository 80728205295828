import React, { useEffect } from 'react'
import Header from '../components/includes/Header';
import Footer from '../components/includes/Footer';
import styled from 'styled-components';
import Breadecrumb from '../components/pages/bloginner/Breadecrumb';
import BlogArea from '../components/pages/bloginner/BlogArea';
import BottomContactUs from '../components/pages/bloginner/BottomContactUs';

function BlogInner() {
  return (
    <>
    <Header/>
    <Section>
        <Breadecrumb/>
        <BlogArea/>
    </Section>
    <BottomContactUs/>
    <Footer/>
    </>
  )
}

export default BlogInner;

const Section = styled.div`
    width:80%;
    margin: 0 auto;

    @media(max-width:1440px){
      width:85%;
    }
`;
