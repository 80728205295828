import React from 'react'
import { styled } from 'styled-components';

function TopContent({item}) {
  return (
    <Section>
        <BreadcrumbArea>
          <Title>
            Projects /
          </Title>
          <Path>
            /Projects/{item?.project_name}
          </Path>
        </BreadcrumbArea>
        <Content>
            <LocationInfo>
                <p>
                Location: <span>{item?.location}</span>
                </p>
            </LocationInfo>
            <ParaArea>
             {
              item?.project_description
             }
            </ParaArea>
        </Content>
    </Section>
  )
}

export default TopContent;


const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0 0px;
    margin-top: 0;
    padding-left: 30px;

    @media(max-width:968px){
      padding-left: 20px;
    }

    @media(max-width:580px){
        padding-left: 10px;
    }
`;

const BreadcrumbArea = styled.div`
    display: flex;
    justify-content:space-between;
    @media(max-width:319px){
      flex-direction: column;
    }
`;

const Title = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
`;

const Path = styled.p`
    font-family: 'raleway_light';
    font-size: 14px;
    
    @media(max-width:425px){
      font-size: 13px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const LocationInfo = styled.div`
font-family: 'raleway_light';
@media(min-width:2000px){
    font-size:25px;
}
@media(min-width:1440px){
  font-size:20px;
}
@media(max-width:1440px){
  font-size:17px;
}

span{
  font-family: 'raleway_semibold';
  font-size: 18px;
  @media(min-width:2000px){
   font-size: 30px;
  }
  @media(min-width:1440px){
   font-size: 25px;
  }
  @media(max-width:1440px){
  font-size:21px;
  }
}
`;

const ParaArea = styled.div`
    width: 100%;
    display: flex;
    align-items:center;
    /* font-family: 'raleway_medium'; */
    /* font-size: 15px; */
    font-family: var(--ff);
    font-size: var(--para-fs);
    line-height: 22px;
    text-align: justify;

    @media(min-width:2000px){
      font-size: 18px;
      line-height: 28px;
    }
    @media(max-width:1400px){
       
    }
    @media(max-width:1024px){
     
    }
    @media(max-width:580px){
      font-size: 13px;
    }
`;

