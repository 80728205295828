import React from 'react'
import styled from 'styled-components';
import centerImg from '../../../assets/images/AboutPage/centerbg.jpg';

function AboutContent() {
  return (
    <Section>
    <Img>
       <img loading='lazy'  src={centerImg} alt="" />
    </Img>
    <BottomSection>
     <Info>
        <InfoPara>
        For our esteemed clients, Classic Insides utilises state-of-the-art machinery to deliver exceptional results.
        We firmly believe in the quality of indigenous products when manufactured in-house. Our factory at Kinfra Export Promotion Industrial Park in Kochi is a testament to this belief.
        A few of the machines we employ to create our brilliance in-house are:
        </InfoPara>
        <BulletPoints>
           <OnePoint>
           Altendorf Sliding Panelsaw Machine is used to harness German technology which facilitates easy cutting of panels, profiles, solid wood, plywood, MDF, laminates, plastic sheets and melamine sheets
           </OnePoint>
           <OnePoint>
           Hydraulic Cold Press Machine is used for pressing laminates on plywood to enhance their durability and eliminate the scope of undulations.
           </OnePoint>
           <OnePoint>
           Automatic Edge Banding Machine is used for covering the exposed sides of materials such as plywood, particle board or MDF.
           </OnePoint>
        </BulletPoints>
     </Info>
     <Right>
         <Heading>
         A Household Name in Classic Craftsmanship
         </Heading>
         <Para>
         As one of the top interior designers in India, we push the boundaries of conventional design, seamlessly merging luxury with efficiency.
        Our expertise spans across both commercial and residential spaces, offering a unique design experience tailored to your lifetime dream.
         </Para>
         <Para>
         Classic Insides is your destination for renowned interior design services. We ensure that the results are a reflection of your personal tastes and aspirations,
         making your space truly your own.
         </Para>
     </Right>
    </BottomSection>
    </Section>
  )
}

export default AboutContent;

const Section = styled.div`
    width: 100%;
    margin-bottom: 40px;
`;

const Img  = styled.div`
    width: 100%;
    
    img{
        width: 100%;
        display: block;
        @media(max-width:580px){
            height:250px;
        };
    }
`;

const BottomSection = styled.div`
    width: 100%;
    display: flex;

    @media(max-width:1130px){
          flex-direction: column;
    };
`;

const Info = styled.div`
    width: 55%;
    padding-top: 40px;
    padding-right: 20px;
    @media(max-width:1130px){
        width:100%;
    };
`;

const InfoPara = styled.p`
    padding: 0;
    margin: 0; 
    /* font-family: 'raleway_medium'; */
    /* line-height:25px; */
    line-height: var(--line-height);
    /* font-size:14px; */
    font-family: var(--ff);
    font-size: var(--para-fs);
    margin-bottom: 20px;
    @media(max-width:1130px){
      text-align: left;
    };

`;

const BulletPoints = styled.div`
    display:flex;
    flex-direction: column;
    gap:10px;
`;

const OnePoint = styled.div`
      padding-left:25px;
      position: relative;
      margin: 0; 
      margin-top: 15px;
      /* font-family: 'raleway_medium'; */
      /* line-height:22px; */
      line-height: var(--line-height);
      /* font-size:14px; */
      font-family: var(--ff);
      font-size: var(--para-fs);
      &::after{
        content: "";
        position: absolute;
        width:10px;
        height:10px;
        border: 2px solid var(--primary-cl);
        top: 5px;
        left: 0;
      }
`;

const Right = styled.div`
  width:45%;
  background-color: var(--grey-white-cl);
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 23px;

  @media(max-width:1130px){
    width:100%;
    margin-top: 25px;
    box-sizing: border-box;
  };

  @media(max-width:580px){
    padding:40px 20px;
  };
  
`;

const Heading = styled.p`
      font-family: 'wensley_bold';
      font-size: 25px;
      padding: 0;
      margin: 0;
      color:var(--secondary-cl);
`;

const Para = styled.p`
    padding: 0;
    margin: 0; 
    margin-top: 15px;
    /* font-family: 'raleway_medium'; */
    /* line-height:25px; */
    line-height: var(--line-height);
    /* font-size:14px; */
    font-family: var(--ff);
    font-size: var(--para-fs);

    @media(max-width:1130px){
      text-align: left;
    };
`;

