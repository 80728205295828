import React, { useState } from "react";
import { styled } from "styled-components";

import { useEffect } from "react";
import axios from "axios";
import { GetSingleContent } from "../../../axios/api";

function SeoArea() {
  const [show, setshow] = useState(false);
  const [data, setdata] = useState({});

  const path = window.location.pathname;

  useEffect(() => {
    axios
      .get(GetSingleContent, {
        params: {
          page: path,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data.app_data;

        if (StatusCode === 6000) {
          setdata(data[0]);
        } else {
          setdata({});
        }
      });
  }, []);

  // contionaly returning
  if (data?.content) {
    return (
      <Section>
        <Container>
          <Box
            className={!show ? "show" : "hide"}
            dangerouslySetInnerHTML={{
              __html: data?.content?.slice(0, 750) + ".....",
            }}
          />
          {data?.content?.length > 750 && (
            <ReadMore className={!show && "show"} onClick={() => setshow(true)}>
              Read More
            </ReadMore>
          )}
          <Box
            className={show ? "show" : "hide"}
            dangerouslySetInnerHTML={{ __html: data?.content }}
          />
          {data?.content?.length > 750 && (
            <ReadMore className={show && "show"} onClick={() => setshow(false)}>
              Read Less{" "}
            </ReadMore>
          )}
        </Container>
      </Section>
    );
  } else {
    return null;
  }
}

export default SeoArea;

const Section = styled.div`
  padding-top: 30px;
`;

const Container = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: flex-end;
  align-items: center;
  max-width: 1920px;
`;

const Title = styled.h1`
  font-family: "wensley_light";
  font-size: 35px;
  text-align: center;
  font-weight: 700;
  padding: 0;
  margin: 0;

  &.process_title {
    text-align: left;
    width: 100%;
  }

  @media screen and (max-width: 580px) {
    font-size: 24px;
    text-align: center;

    &.process_title {
      text-align: center;
    }
  }
`;

const Para = styled.p`
  text-align: justify;
  padding: 0;
  font-family: var(--ff);
  font-size: var(--para-fs);
  line-height: var(--line-height);
  margin: 0;
  width: 100%;

  @media screen and (max-width: 580px) {
    &.second-para {
      display: none;
    }
    &.show {
      &.second-para {
        display: block;
      }
    }
  }
`;

const Box = styled.div`
  margin: 15px 0;
  width: 100%;
  p {
    text-align: justify;
    padding: 0;
    font-family: var(--ff);
    font-size: var(--para-fs);
    line-height: var(--line-height);
    margin: 8px 0;
    width: 100%;
  }

  span {
    font-family: var(--ff);
  }

  h2,
  h3,
  h4,
  h5 {
    font-family: "wensley_light";
    font-size: 35px;
    text-align: left;
    font-weight: 700;
    padding: 0;
    margin: 8px 0;
  }

  h3,
  h3,
  h4,
  h5 {
    span {
      font-size: 21px;
    }
  }

  @media screen and (max-width: 580px) {
    h2,
    h3,
    h4,
    h5 {
      font-size: 24px;
    }
  }

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
`;

const ReadMore = styled.div`
  font-family: "raleway_light";
  font-size: 15px;
  color: #525252;
  text-align: right;
  display: none;
  cursor: pointer;
  margin: 15px 0;
  width: 100%;

  &.show {
    display: block;
  }
`;
