import React from 'react'
import styled from 'styled-components';

function BottomSection() {
  return (
    <Section>
       <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62870.121970471104!2d76.27481413989636!3d9.985218502465033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d87c43ec475%3A0xf88a912fce8e7c79!2sClassic%20Insides!5e0!3m2!1sen!2sin!4v1695286360129!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </Section>
  )
}

export default BottomSection;


const Section = styled.div`
        width:100%;
        margin: 25px 0;
        iframe{
            width:100%;
            height:550px;
            border:0;
            @media(max-width:580px){
              height:400px;
            }
        }
`;