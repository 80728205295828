import React from 'react'
import { styled } from 'styled-components';

function FactoryBanner({data}){

  return (
    <Section bg={data?.factory_banner}>
         <Content>
            <Title>
                {data?.title}
            </Title>  
            <Body>
             {data?.body}
            </Body>
         </Content>
    </Section>
  )
}

export default FactoryBanner;


const Section = styled.div`
${(props)=> props.bg && `background-image : url(${props.bg})`};
width:100%;
height:800px;
position:relative;
background-repeat:no-repeat;

@media(min-width:2000px){
  background-size: cover;
  background-position:center center;
  height: 930px;
}
@media(max-width:2000px){
  background-size: cover;
  background-position:center center;
}

@media(max-width:1375px){
    height:700px;
}

@media(max-width:580px){
    height:350px;
    margin-top: 145px;
}

@media(max-width:300px){
    margin-top: 185px;
}
`;

const Content  = styled.div`
position: absolute;
color: black;
top: 20%;
width: 40%;
left: 54%;
@media(max-width:2200px){
    left: 50% !important;
}
@media(min-width:1900px){
    top:8%;
    left: 60%;
}

@media(max-width:2000px){
    top:8% !important;
}

@media(max-width:800px){
    top:0% !important;
    left: 42% !important;
}

@media(max-width:580px){
    top: -165px!important;
    left: 0px!important;
    width:70%;
    padding:10px;
}

@media(max-width:330px){
    top: -175px!important;
}

@media(max-width:300px){
    top: -210px!important;
}

`;

const Title = styled.h1`
font-family: 'wensley_bold';

@media(max-width:425px){
    margin-bottom: 3px;
}

`;

const Body = styled.p`
/* font-family: 'raleway_medium';
font-size: 12px;
line-height: 18px; */
font-weight: 300;
font-family: var(--ff);
font-size: var(--para-fs);
line-height: var(--line-height);
width:48ch;
/* @media(min-width:1900px){
    font-size: 16px;
    line-height: 22px;
} */
@media(max-width:580px){
   line-height: 22px;
}
@media(max-width:420px){
    width:47ch;
    /* font-size: 11px; */
}
@media(max-width:330px){
    width:40ch;
}
@media(max-width:300px){
    width:35ch;
}
`;