import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../components/includes/Header";
import styled from "styled-components";
import Footer from "../components/includes/Footer";
import ServiceBanner from "../components/pages/services/ServiceBanner";
import ServiceSection from "../components/pages/services/ServiceSection";
import { ListSingleSeoData } from "../axios/api";
import Helmet from "../components/utils/Helmet";
import SeoArea from "../components/pages/home/SeoArea";

function Service() {
  const [seoData, setseoData] = useState({});
  const path = window.location.pathname;

  // get seo Data
  useEffect(() => {
    axios
      .get(ListSingleSeoData, {
        params: {
          links: path,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data.app_data;

        if (StatusCode === 6000) {
          setseoData(data[0]);
        } else {
          setseoData({});
        }
      });
  }, []);

  return (
    <>
      <Helmet
        titleData={seoData?.meta_title}
        descriptionData={seoData?.meta_description}
      />
      <Header />
      <ServiceBanner />
      <Section>
        <ServiceSection />
      </Section>
      <SeoArea/>
      <Footer />
    </>
  );
}

export default Service;

const Section = styled.div`
  width: 80%;
  margin: 50px auto 0px;

  @media (max-width: 1200px) {
    margin: 70px auto;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }
`;
