import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';

import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';




function AssetArea({item}) {
  const [data, setdata] = useState(null);
  const [assets, setassets] = useState([]);
  const [state, setstate] = useState(false);
  const [count, setcount] = useState(4);
  const [load, setload] = useState(true);
  const navigate = useNavigate();

  const memoizedAssets = useMemo(() => item?.project_asset, [item?.project_asset]);
  const data_length = item?.project_asset?.length;
  const assets_length = data?.length;
  useEffect(() => {
    setdata(item?.project_asset)
  }, [data_length])


  useEffect(() => {
    if(data){
      
      if(data.length === 1){
        let temp={
          ...data[0],
          width:'90',
          smWidth:'90',
          id:1
        }
        setassets([temp]);
        setload(false);
      }else{
        let currentList = data.slice(0,count);
    
        const pattern = [40,40,40,40,40,40,40,40,40]
        const Mobilepattern = [60,39,39,60]
        let counter = 2;
        let current = 0;
        let mobileCurrent = 0;
        const newMyArray = currentList.map((obj)=>{
            let temp={
              ...obj,
              width:pattern[current],
              smWidth:Mobilepattern[mobileCurrent],
              id:counter
            }
            current = current + 1 === pattern.length ? 0 : current + 1;
            mobileCurrent = mobileCurrent + 1 === Mobilepattern.length ? 0 : mobileCurrent + 1;
            counter++;
  
            return temp;
        })
  
        setassets(newMyArray);
      
        if(count >= data.length){
        setload(false)
        }else{
        setload(true)
        }
        }
      }
      
  }, [state,assets_length])
  
  const handleLoadMore = () => {
  
      if(!data.length > count){
         setload(false)
      }else{
         setcount((count)=>count+4);
         setstate(!state);
      }
  };

  return (
    <Section>
      <ProjectListArea>
        {assets?.map((obj,indx)=>{
            
            if(indx % 2 === 0){
                return(
                    <SingleProject w={obj.width} sm={obj.smWidth} >
                      <Container>
                        {
                          obj?.asset_type === "image" ? (
                            <img loading='lazy'  src={obj?.project_image} alt="" />
                          ) : (
                            <ReactPlayer  width={"100%"} height={"100%"} url={obj?.computer_video} playing={true}  playsinline muted controls loop/>
                          )
                        }
                      </Container>
                    </SingleProject>
                )

            }else{

                return(
                    <SingleProject w={obj.width} sm={obj.smWidth}>
                      <Container>
                        {
                          obj?.asset_type === "image" ? (
                            <img loading='lazy'  src={obj?.project_image} alt="" />
                          ) : (
                            <ReactPlayer  width={"100%"} height={"100%"} url={obj?.computer_video} playing={true}  playsinline muted controls loop/>
                          )
                        }
                        
                      </Container>
                    </SingleProject>
                )

            }
            
        })}
      </ProjectListArea>
      {
        load &&
        <LoadButton>
        <button onClick={()=>handleLoadMore()}>
           Load More
        </button>
        </LoadButton>
      }
     
    </Section>
  )
}

export default AssetArea;



const Section = styled.div`
  width:100%;
  @media(max-width:580px){
    width:100%;
  }
`;

const ProjectListArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap:15px 8px;
  /* grid-template-columns: 40% 60%; */
  /* row-gap: 15px; */
  margin:20px 0;
  justify-content:center;
  align-items:center;

  @media(max-width:580px){
  row-gap: 5px;
  }
`;

const LoadButton = styled.div`
width: 100%;
margin: 0px 0px 25px;
display: flex;
justify-content: center;
align-items:center;

@media(max-width:1400px){
    margin: 40px 0px 25px;
    
}

button{
    border:1px solid var(--primary-cl);
    background-color: white;
    padding: 12px  26px;
    font-size:14px;
    font-family: 'raleway_medium';
    color: #000;

    &:hover{
        background-color: var(--primary-cl);
        color: #fff;
    }
}
`;

const SingleProject = styled.div`
max-height: 600px;
${(props)=>props.w && `width:${props.w}%;`}

@media(max-width: 1024px){
  ${(props)=>props.sm && `width:${props.sm}%;`}
}

@media(max-width: 900px){
  width: 49%;
}
@media(max-width:580px){
    width:100%;
    position: relative;
    /* &::after{
      content:'';
      position: absolute;
      inset: 0;
      background-color:#000;
      opacity: .3;
    } */
}

@media(max-width:480px){
  width: 100%;
}

`;

const Container = styled.div`
width: 100%;
position: relative;
height: 90%;
@media(max-width:580px){
  height: 100%;
}
img{
    width: 100%;
    height: 100%;
    display: block;
}
video{
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
    object-fit: cover;
}
`;

const Name = styled.div`
@media(max-width:580px){
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 25;
}
p{
margin-top: 12px;
font-family: 'raleway_medium';
margin-bottom:16px;

@media(max-width:1400px){
    font-size:14px;
}

@media(max-width:580px){
    color: #fff;
    font-size:21px;
    text-align:center;
}

@media(max-width:480px){
    margin-top: 8px;
}
@media(max-width:360px){
    width:17ch;
    font-size:19px;
  }

}
`;








































































































// const Section = styled.div`
//   padding-left: 30px;
//   /* height: 650px; */
//   height: auto;
//   margin: 50px 0px 75px;

//   @media(min-width:2000px){
//     /* height: 750px; */
//   }
//   @media(max-width:1440px){
//     /* height: 550px; */
//   }
//   @media(max-width:968px){
//     padding-left: 20px;
//   }
//   @media(max-width:580px){
//     padding-left: 10px;
//     /* height: 375px; */
//   }

//   @media(max-width:450px){
//     /* height: 325px; */
//     padding-left: 0px;
//   }
//   @media(max-width:375px){
//     /* height: 250px; */
//   }
// `;
  //return (
  //   <>
  //   <Section>
  //     {/* <AssetSlider assets={item?.assets}/> */}
  //   </Section>
  //   </>
// )