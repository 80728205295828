import React from 'react'
import { styled } from 'styled-components';


import slider1 from '../../../assets/images/slider/slider-1.jpg';
import slider2 from '../../../assets/images/slider/slider-2.jpg';
import slider3 from '../../../assets/images/slider/slider-3.jpg';

import aboutImage from '../../../assets/images/about/mob/about-mob.png';
import insides from '../../../assets/images/other/insides-img.svg'

import CustomImageSlider from '../../sliders/CustomImageSlider';
import { imgs } from '../../../assets/data/sliderImgs';
import { useNavigate } from 'react-router-dom';

function AboutSection() {
  
  const navigate = useNavigate();

  return (
    <Section>
      <About bg={insides}>
        <Breadcrumb>
          About /
        </Breadcrumb>
        <MobileImageSlider>
          <img loading='lazy' src={aboutImage} alt="" />
        </MobileImageSlider>
        <ContentArea bg={insides}>
          <Div>
          <Heading>
          A Classic Tale
          </Heading>
          <SubHeading>
            Interior Designers in Kochi, Ernakulam
          </SubHeading>
          <Paragraph>
          Classic Insides boasts the premiere status of the best interior designers in Kochi.
          From planning to execution, our disciplined and dedicated team works to ensure unmatched quality and functionality.
          With vast knowledge in the realm of materials and using our spatial experience, our designers can optimise space and schedule & execute designs that are out of this world.
          </Paragraph>
          </Div>
          <ReadMoreButton>
          <button onClick={()=>navigate('/about')}>
            Read More
          </button>
        </ReadMoreButton>
        </ContentArea>
      </About>
      <CustomImageSliderArea>
          <CustomImageSlider imgs={imgs} />
      </CustomImageSliderArea>
    </Section>
  )
}

export default AboutSection;


const Section = styled.div`
  display: flex;
  margin-bottom: 40px;
  height: 75vh;
  min-height: 438px;
  max-height: 650px;
  @media(min-width:1875px){
    width:85%;
    margin: 0px auto 40px auto;
  }
  @media(max-width:676px){
    max-height: max-content;
    height: auto;
  }
  @media(max-width:580px){
    flex-direction: column;
  }
`;

const Div = styled.div`
 padding-right: 30px;
 @media(min-width:580px){
      padding-right: 0px;
  }
`;

const About = styled.div`
  width:50%;
  padding:0px 0px 0px 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  background-repeat: no-repeat;
  background-size: contain;
  background-position:right 0px;
  @media(min-width:580px){
    ${(props)=>props.bg && `background-image: url(${props.bg})`};
  }
  @media(min-width:1875px){
    padding:0px 0px 0px 15px;
  }
  @media(min-width:1600px){
    justify-content:center;
  }
  @media(max-width:1440px){
    padding:0px 0px 0px 25px;
  }
  @media(max-width:968px){
    padding:0px;
  }
  @media(max-width:580px){
    width:100%;
    padding:0px 0px 35px 0px;
  }
`;

const MobileImageSlider = styled.div`
 width: 90%;
 margin:0px auto;

 img{
  width: 100%;
  display:block;
 }

 @media(min-width:580px){
  display: none !important;
 }
 .slick-next{
  display:none !important;
 }
 .slick-center{
  transform:scale(1.03);
 }
`;

const Image = styled.div`
width:98%;
${(props)=>props.img&&`background-image:url(${props.img})`};
background-repeat:no-repeat;
background-size:cover;
background-position:center center;
height:350px;

/* img{
  display: block !important;
  width: 100%;
} */
`;

const Breadcrumb = styled.div`
   display: flex;
   align-items:start;
   justify-content: baseline;
   padding: 40px 10px;
   width:75%;
   font-family: 'raleway_light';
   font-size: 15px;
   @media(max-width:968px){
    padding: 25px 10px;
   }
   @media(max-width:676px){
      width: 90%;
   } 
   @media(max-width:580px){
    padding: 30px 10px;
   }
`;

const ContentArea = styled.div`
    width:75%;
    display: flex;
    align-items:start;
    flex-direction: column;
    margin-top: 40px;
    
    background-repeat: no-repeat;
    background-size: 80px;
    background-position:right 30px ;

    @media(max-width:968px){
      width:85%;
    }


    @media(max-width:580px){
    width:90%;
    height: max-content;
    ${(props)=>props.bg && `background-image: url(${props.bg})`};
    }

    @media(max-width:420px){
      background-size: 85px;
    }
`;


const Heading = styled.h1`
   font-family:'raleway_semibold';
   /* font-family:'wensley_bold'; */
   margin-bottom:10px;
   font-size:35px;
   color: var(--primary-cl);
   @media(max-width:968px){
    font-size:33px;
  }
  @media(max-width:580px){
    font-size:26px;
  }
`;

const SubHeading = styled.p`
    font-family: "raleway_semibold";
    font-size: 15px;
    margin-bottom:10px;
    font-weight:bold;
`;

const Paragraph = styled.p`
   /* font-family: 'raleway_regular'; */
   font-family: var(--ff);
   font-size: var(--para-fs);
   /* line-height: 25px; */
   line-height: var(--line-height);
   /* font-size: 15px; */
   font-weight:500;
   text-align: left;
   /* @media(max-width:968px){
    font-size:13px;
   } */
    @media(max-width:580px){
    width: 85%;
    
   }
   @media(max-width:430px){
    width: 80%;
    
   }
  @media(max-width:350px){
    width: 100%;
  }
`;

const ReadMoreButton = styled.div`
      display:flex;
      width:75%;
      @media(max-width:580px){
      width:85%;
      justify-content: start;
      }
      button{
      font-family: 'raleway_semibold';
      border: 1px solid var(--primary-cl);
      padding:15px 30px;
      background-color:#fff;
      float:left;
      margin:10px 0px;
      transition: all .5s ease-in-out;
      color: #000;
      cursor: pointer;
      &:hover{
        color:#fff;
        background-color: var(--primary-cl);
      }
      }
`;

const CustomImageSliderArea = styled.div`
  width:50%;
  display: flex;
  height:100%;
  @media(max-width:580px){
    display: none;
  }
`;


