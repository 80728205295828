import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import LeftArrow from "../../../assets/images/icons/left-arrow.svg";
import RightArrow from "../../../assets/images/icons/right-arrow.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";

import { styled } from "styled-components";
import { HeaderContext } from "../../../context/StoreContext";
import axios from "axios";
import { BannerListApi } from "../../../axios/api";
import { useNavigate } from "react-router-dom";
import ContactUsModal from "../../modal/ContactUsModal";

function Banner() {
  const [mouseEnter, setmouseEnter] = useState(false);
  const [positionX, setpositionX] = useState(0);
  const [positionY, setpositionY] = useState(0);
  const [position, setposition] = useState(null);
  const [ctaBtnHover, setctaBtnHover] = useState(false);
  const [modal, setmodal] = useState(false);

  const [videos, setvideos] = useState([]);

  const [pos, setpos] = useState(0);
  const navigate = useNavigate();

  const divRef = useRef(null);

  const { state } = useContext(HeaderContext);

  const width = window.innerWidth;

  // Fetching Banner Videos
  useEffect(() => {
    axios.get(BannerListApi).then((response) => {
      const { data, StatusCode } = response.data.app_data;

      if (StatusCode === 6000) {
        setvideos(data);
      } else {
        setvideos([]);
      }
    });
  }, []);

  const handleMouseMove = (e) => {
    const width = divRef.current.offsetWidth / 2;
    if (width < e.clientX) {
      setposition("R");
    } else {
      setposition("L");
    }
    setpositionX(e.clientX);
    setpositionY(e.clientY);
  };

  const handleMouseEnter = () => {
    setmouseEnter(true);
  };

  const handleMouseLeave = () => {
    setmouseEnter(false);
  };

  const handleMouseDown = (e) => {
    if (e.target.classList.contains("cta")) {
      setmodal(true);
      return false;
    }

    if (state.active) {
      return false;
    }

    const viewWidth = window.innerWidth;
    const { clientX } = e;
    const width = divRef.current.offsetWidth / 2;
    if (width < clientX) {
      if (viewWidth < 580) {
        if (pos + 1 > videos.length - 1) {
          setpos(0);
        } else {
          setpos((num) => num + 1);
        }
      } else {
        if (pos + 1 > videos.length - 1) {
          setpos(0);
        } else {
          setpos((num) => num + 1);
        }
      }
    } else {
      if (viewWidth < 580) {
        if (pos - 1 < 0) {
          setpos(videos.length - 1);
        } else {
          setpos((num) => num - 1);
        }
      } else {
        if (pos - 1 < 0) {
          setpos(videos.length - 1);
        } else {
          setpos((num) => num - 1);
        }
      }
    }
  };

  // auto play the next video a video ends plays
  const Nextvideo = () => {
    const viewWidth = window.innerWidth;
    if (viewWidth < 580) {
      if (pos + 1 > videos.length - 1) {
        setpos(0);
      } else {
        setpos((num) => num + 1);
      }
    } else {
      if (pos + 1 > videos.length - 1) {
        setpos(0);
      } else {
        setpos((num) => num + 1);
      }
    }
  };

  return (
    <>
      <Section
        className={`Desktop ${ctaBtnHover && "show-pointer"} `}
        ref={divRef}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
      >
        {width > 580 &&
          videos?.length > 1 &&
          !ctaBtnHover &&
          !state.active &&
          mouseEnter && (
            <Arrow style={{ left: `${positionX}px`, top: `${positionY}px` }}>
              {position === "R" ? (
                <ArrowImg src={RightArrow} />
              ) : (
                <ArrowImg src={LeftArrow} />
              )}
            </Arrow>
          )}
        {videos?.length > 1 && (
          <MobArrows>
            <div>
              <KeyboardBackspaceIcon />
            </div>
            <div>
              <EastIcon />
            </div>
          </MobArrows>
        )}

        <VideoContainer>
          <Content>
            <Title>{videos[pos]?.title}</Title>
            <Desc>{videos[pos]?.content}</Desc>
            <CTA
              className="cta"
              onMouseOver={() => setctaBtnHover(true)}
              onMouseLeave={() => setctaBtnHover(false)}
            >
              Enquire Now
            </CTA>
          </Content>
          <Video>
            <ReactPlayer
              onEnded={() => Nextvideo()}
              width={"100%"}
              height={"100%"}
              url={videos[pos]?.desktop_video}
              playing={true}
              playsinline
              muted
            />
          </Video>
          <MobVideo>
            <ReactPlayer
              onEnded={() => Nextvideo()}
              width={"100%"}
              height={"100%"}
              url={videos[pos]?.mobile_video}
              playing={true}
              playsinline
              muted
            />
          </MobVideo>
        </VideoContainer>
      </Section>
      <ContactUsModal isModal={modal} setModal={setmodal} />
    </>
  );
}

export default Banner;

const Section = styled.div`
  position: relative;
  cursor: none;
  overflow: hidden;
  height: 100vh;
  width: 100%;

  &.show-pointer {
    cursor: pointer;
  }

  @media (min-width: 2000px) {
    height: calc(100vh - 130px);
  }
  @media (max-width: 1999px) {
    height: calc(100vh - 100px);
  }
  @media (max-width: 1750px) {
    height: calc(100vh - 75px);
  }

  @media (max-width: 1325px) {
    height: calc(100vh - 85px);
  }

  @media (max-width: 800px) {
    height: calc(100vh - 65px);
  }

  @media (max-width: 676px) {
    height: 125vw;
  }
`;

const Arrow = styled.div`
  position: absolute;
  color: #000000;
  z-index: 1000;
`;
const IndexData = styled.div`
  position: absolute;
  z-index: 2500;
  left: 125px;
  bottom: 30px;
  font-family: "raleway_regular";
  font-size: 16px;
  @media (max-width: 580px) {
    left: 15px;
    bottom: 20px;
  }
`;
const BannerImg = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media (max-width: 580px) {
    /* ${(props) => props.bg && `background-image:url(${props.bg})`}; */
    height: 100%;
    /* margin-top:75px; */
  }
  img {
    width: 100%;
    display: block;
    transition: transform 200ms linear;
    /* @media(max-width:580px){
    display:none;
    } */
  }
`;

const MobArrows = styled.div`
  display: none;
  width: 96%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: 50%;
  margin: 0 auto;
  transform: translate(2%);

  @media (max-width: 580px) {
    display: flex;
  }
`;

const MobSection = styled.div`
  position: relative;
  cursor: none;
  overflow: hidden;
  .slick-next {
    right: 5px;
    z-index: 200;
  }
  .slick-prev {
    left: 5px;
    z-index: 200;
  }
  @media (min-width: 580px) {
    display: none;
  }
`;

const OverLayArea = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 55px;
  flex-direction: column;
  left: 45px;
`;

const Body = styled.p`
  font-family: "raleway_medium";
  color: #fff;
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 18px;
`;

const CTC = styled.p`
  font-family: "raleway_medium";
  color: #fff;
  margin: 0 0;
  margin-top: 10px;
  width: 100%;
  font-size: 15px;
  letter-spacing: 1px;
  position: relative;
  transition: width 1s ease;

  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    height: 1px;
    width: 70%;
    background-color: #fff;
    left: 0;
  }

  &:hover {
    color: #cecbcb;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Video = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 676px) {
    display: none;
  }
  video {
    object-fit: cover;
  }
`;

const MobVideo = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
  @media (max-width: 676px) {
    display: block;
  }
  video {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  bottom: 5vh;
  left: 14vw;

  @media (max-width: 1400px) {
    left: 10vw;
  }

  @media (max-width: 676px) {
    left: 5vw;
  }
  @media (max-width: 350px) {
    left: 3vw;
  }
`;

const Title = styled.h3`
  font-size: 42px;
  color: white;
  font-family: "wensley_light";
  width: 375px;
  margin: 10px 0;
  padding: 0;
  line-height: 40px;
  text-transform: capitalize;
  @media (max-width: 676px) {
    font-size: 35px;
  }
  @media (max-width: 400px) {
    width: 315px;
  }
`;

const Desc = styled.p`
  font-size: 15px;
  color: white;
  font-family: "raleway_regular";
  padding: 0;
  margin: 5px 0;
  width: 400px;

  @media (max-width: 480px) {
    width: 275px;
  }
  @media (max-width: 380px) {
    width: 250px;
  }
`;

const ArrowImg = styled.img`
  width: 100%;
  height: 30px;
`;

const CTA = styled.a`
  padding: 10px 20px;
  border: none;
  background-color: var(--primary-cl);
  font-family: "raleway_regular";
  color: #fff;
  display: flex;
  font-size: 16px;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 65%;
  cursor: pointer;
  position: relative;
  z-index: 200;
  margin-top: 15px;
  border-radius: 5px;
  @media (max-width: 580px) {
    width: 70%;
    padding: 12px 12px;
  }
`;
