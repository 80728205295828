import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import {
  EmailShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon
} from "react-share";

import pic1 from "../../../assets/images/blog/innerPage/Asset1.png";
import pic2 from "../../../assets/images/blog/innerPage/Asset2.png";
import pic3 from "../../../assets/images/blog/innerPage/Asset3.png";
import pic4 from "../../../assets/images/blog/innerPage/Asset4.png";
import pic5 from "../../../assets/images/blog/innerPage/Asset5.png";
import printIcon from "../../../assets/images/blog/innerPage/icons/print.svg";
import copyIcon from "../../../assets/images/blog/innerPage/icons/copy.svg";
import copiedIcon from "../../../assets/images/blog/innerPage/icons/copied.svg";
import shareIcon from "../../../assets/images/blog/innerPage/icons/share.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ListBlogsApi, ListSingleBlogsApi } from "../../../axios/api";
import Helmet from "../../utils/Helmet";

function BlogArea() {
  const [copied, setcopied] = useState(false);
  const [share, setshare] = useState(false);
  const [blog, setblog] = useState({});
  const [blogs, setblogs] = useState({});
  const url = window.location.href;
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(ListSingleBlogsApi+slug).then((response)=>{
      const { data , StatusCode } = response.data.app_data;

      if(StatusCode === 6000){
         setblog(data[0]);
      }else if(StatusCode === 6001){
        navigate('/blogs')
      }
    })
    axios.get(ListBlogsApi,{
      params: {
        page: 1,
      },
    })
    .then((response) => {
      const { StatusCode, data } = response.data.app_data;
      if (StatusCode === 6000) {
        setblogs(data);
      }
    });
  }, [])
  
  const handlePrint = () => {
    window.print();
  };

  const handleCopy = () => {
    setcopied(true);
    setTimeout(() => {
      setcopied(false);
    }, 3000);
  };
  return (
    <>
    <Helmet
        titleData={blog?.meta_tag}
        descriptionData={blog?.meta_description}
      />
    <Section>
      <Body>
        <Title>
          {blog?.title}
        </Title>
        <Image>
          <img src={blog?.blog_image} alt="" />
        </Image>
        <Date>{blog?.created}</Date>
        <Content dangerouslySetInnerHTML={{__html:blog?.body}}>
          
        </Content>
        {/* <Buttons>
          <PrevBtn>Previous</PrevBtn>
          <NextBtn>Next</NextBtn>
        </Buttons> */}
        <ActionButtons>
            <Button onClick={handlePrint}>
              <img src={printIcon} alt="" />
            </Button>
            <Button>
              <CopyToClipboard text={url} onCopy={() => handleCopy()}>
                <img src={copied ? copiedIcon : copyIcon} alt="" />
              </CopyToClipboard>
            </Button>
            <Button onClick={()=>setshare(!share)}>
              <img src={shareIcon} alt="" />
              {
                share && 
                <ShareBtnContainer >
                <FacebookShareButton url={url}>
                    <FacebookIcon size={32} round={true}/>
                </FacebookShareButton>

                <EmailShareButton url={url}>
                    <EmailIcon size={32} round={true}/>
                </EmailShareButton>

                <WhatsappShareButton url={url}>
                    <WhatsappIcon size={32} round={true}/>
                </WhatsappShareButton>

                <LinkedinShareButton url={url}>
                   <LinkedinIcon size={32} round={true}/>
                </LinkedinShareButton>

                <TwitterShareButton url={url}>
                    <TwitterIcon size={32} round={true}/>
                </TwitterShareButton>
              </ShareBtnContainer>
              }
              
            </Button>
          </ActionButtons>
      </Body>
      <RecentBlogs>
        <Top>
          <p>Recent Blogs</p>
        </Top>
        <Bottom>
          {blogs?.results?.map((item) => {
            return (
              <SmallBlog>
                <img src={item?.resized_image} alt="" />
                <Box>
                  <span>{item.created}</span>
                  <p>
                    {item.title?.length > 55 ? item.title.slice(0,55) + '...' : item.title}
                  </p>
                </Box>
              </SmallBlog>
            );
          })}
        </Bottom>
      </RecentBlogs>
    </Section>
    </>
  );
}

export default BlogArea;

const Section = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;

  @media(max-width:1200px){
    flex-direction: column;
    gap:30px;
  }
`;

const Body = styled.div`
  width: 75%;

  @media(max-width:1200px){
    width:100%
  }
`;

const RecentBlogs = styled.div`
  width: 25%;

  @media(max-width:1200px){
    width:100%;
    margin-bottom:20px;
  }
`;

const Title = styled.p`
  font-family: "wensely_bold";
  font-size: 2.4rem;
  width: 100%;
  text-align: left;
  margin: 0px;
  height: 100px;

  @media(max-width:1400px){
    font-size: 2rem;
  }
  @media(max-width:1400px){
    font-size: 1.5rem;
  }
  @media(max-width:580px){
    height: 50px;
  }
  @media(max-width:450px){
    font-size: 1.2rem;
    height: 85px;
  }
  @media(max-width:320px){
    font-size: 1rem;
  }
`;

const Image = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;


const Date = styled.p`
  width: 100%;
  text-align: start;
  font-family: "raleway_light";
  color: grey;
  margin: 20px auto;
  padding: 10px auto;
  font-size: 15px;
  position: relative;
  &::after {
    content: "";
    width: 85%;
    background-color: #a9a9a9;
    height: 1px;
    position: absolute;
    top: 50%;
    right: 0;

    @media(max-width:968px){
        width:75%;
    }
    @media(max-width:580px){
        width:60%;
    }
    @media(max-width:420px){
        width:55%;
    }
  }
`;

const Content = styled.p`
  margin: 20px auto;
  font-family: "raleway_regular";
  font-size: 15px;
  text-align: justify;
  line-height: 22px;
  @media(max-width:580px){
      font-size: 14px;
    }
`;

const Buttons = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin: 40px auto;
`;

const NextBtn = styled.div`
  font-family: "raleway_regular";
  border: 1px solid var(--primary-cl);
  padding: 12px 25px;
  font-size: 15px;
  background-color: #fff;
  float: left;
  margin: 10px 0px;
  transition: all 0.5s ease-in-out;
  color: #000;
  &:hover {
    color: #fff;
    background-color: var(--primary-cl);
  }
`;

const PrevBtn = styled.div`
  font-family: "raleway_regular";
  border: 1px solid var(--primary-cl);
  padding: 12px 25px;
  background-color: #fff;
  float: left;
  margin: 10px 0px;
  transition: all 0.5s ease-in-out;
  color: #000;
  &:hover {
    color: #fff;
    background-color: var(--primary-cl);
  }
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  height: 100px;

  @media(max-width:580px){
    height:85px;
  }

  p {
    font-family: "raleway_semibold";
    font-size: 1.1rem;
    border-bottom: 1px solid grey;
    margin: 0;
    padding: 10px 0;
    height: 20px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;

  @media(max-width:1200px){
    flex-direction: row;
    flex-wrap:wrap;
  }

  @media(max-width:680px){
    flex-direction: column;
  }
`;

const SmallBlog = styled.div`
  display: flex;
  height: 100px !important;
  width: 100%;
  border-bottom: 1px solid grey;
  padding: 25px 0px;
  cursor: pointer;

  &:first-child{
    padding-top:0;
  }

  @media(max-width:1200px){
    width:50%;
    &:nth-child(2){
      padding-top:0;
    }

    &:last-child{
      border:none;
    }
  }

  @media(max-width:680px){
    &:nth-child(2){
      padding-top:25px;
    }
    width:100%;
  }

  @media(max-width:320px){
    flex-direction:column;
    gap:15px;
    height:auto !important;
    padding: 15px 0px;
    &:nth-child(2){
      padding-top:15px;
    }
    img{
      height:100%;
      width:100%;
      display:block;
    }
  }
`;

const Box = styled.div`
  padding: 0px 8px 5px;
  width:60%;
  p {
    font-size: 16px;
    font-family: "raleway_semibold";
    max-height: 2.4em; /* Adjust the height to your preference */
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
  }

  span {
    font-size: 14px;
    font-family: "raleway_regular";
    color: grey;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-bottom: 40px;

  /* @media(max-width:475px){
      justify-content: center;
  } */
`;

const Button = styled.div`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
`;

const ShareBtnContainer = styled.div`
  background-color: #F8F8F8;
  padding: 20px;
  display: flex;
  gap: 15px;
  position: absolute;
  top:calc(18px + 110%);
  right: 0%;
  z-index: 2;

  &::after{
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    top:-19px;
    right: 5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #F8F8F8;
}

`;
