import React, { useEffect, useState } from 'react'
import axios from 'axios';


import Header from '../components/includes/Header';
import Footer from '../components/includes/Footer';
import ProjectsBanner from '../components/pages/projects/ProjectsBanner';
import TopContent from '../components/pages/projects/TopContent';
import styled from '@emotion/styled';
import ProjectsList from '../components/pages/projects/ProjectsList';
import BottomContactUs from '../components/pages/projects/BottomContactUs';
import { ListSingleSeoData } from '../axios/api';
import Helmet from '../components/utils/Helmet';

function Projects() {
  const [seoData, setseoData] = useState({});
  const path = window.location.pathname
  
  // get seo Data
  useEffect(() => {
    axios.get(ListSingleSeoData,{
      params:{
        links:path
      }
    }).then((response) => {
      const { data, StatusCode } = response.data.app_data;

      if (StatusCode === 6000) {
        setseoData(data[0]);
      } else {
        setseoData({});
      }
    });
  }, []);

  return (
    <>
    <Helmet
        titleData={seoData?.meta_title}
        descriptionData={seoData?.meta_description}
      />
    <Header/>
    <ProjectsBanner/>
    <Section>
       <TopContent/>
       <ProjectsList/>
    </Section>
    <BottomContactUs/>
    <Footer/>
    </>
  )
}

export default Projects;


const Section = styled.div`
width: 80%;
margin: 0 auto;

@media(max-width:1024px){
    width: 90%;
}

`;
