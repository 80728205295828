import React, { useEffect } from 'react';
import { styled } from 'styled-components';

import insidesImg from '../../../assets/images/other/insides-img.svg';

function TopInfoSection({item}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[item?.project_name])
  
  return (
   <Section>
    <InsidesImg bg={insidesImg}>
    
    </InsidesImg>
    <BannerAndInfo>
        <img src={item?.top_image} alt="" />
        <OverLayContent>
              <Content>
              <p>{item?.project_type} FOR <br />
              <span>{item?.project_name}</span></p>
              </Content>
        </OverLayContent>
    </BannerAndInfo>
   </Section>
  )
}

export default TopInfoSection;


const Section = styled.div`
display: flex;
/* margin-top:70px; */
`;

const InsidesImg = styled.div`
width: 15%;
${(props)=>props.bg && `
    background-image:url(${props.bg});
`}
background-repeat:no-repeat;
background-size: 76%;
background-position: 65% 23px;
@media(min-width:2000px){
    background-position: 65% 60px;
}
@media(max-width:425px){
    background-size: 80%;
}
@media(max-width:680px){
    display:none;
}
`;

const BannerAndInfo = styled.div`
width: 85%;
position: relative;

@media(max-width:680px){
    width: 100%;
}

img{
    display: block;
    width:100%;
    height:auto;
    /* @media(max-width:425px){
        height: 55vh;
        max-height:375px;
    } */
}
`;

const OverLayContent = styled.div`
position: absolute;
width:35%;
height:100%;
background-color: #fff;
opacity:75%;
top: 0;
right: 0;
display: flex;
justify-content:center;
align-items: flex-end;

@media(max-width:968px){
   width: 52%;
}

@media(max-width:580px){
    width: 100%;
    height: 30px;
    bottom: 0;
    left: 0;
    top:unset;
    right: unset;
}
@media(max-width:420px){
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
    top:unset;
    right: unset;
}
`

const Content = styled.div`
font-size:30px;
@media(max-width:580px){
    width:100%;
    margin:auto;
}
p{
    margin:0;
    text-align: center;
    margin-bottom:30px;
    font-family: 'wensley_regular';
    line-height:26px;
    text-transform: uppercase;
    @media(min-width:2000px){
     font-size:45px;
     line-height:36px;
    }
    @media(max-width:680px){
        font-size: 25px;
        margin-bottom: 15px;
    }
    @media(max-width:580px){
    width:90%;
    margin:auto;
    display: flex;
    font-size: 18px;
    display: flex;
    align-items:center;
    justify-content:center;
    }
    @media(max-width:515px){
        width:100%;
    }
    
    @media(max-width:515px){
        font-size: 16px;
    }

    @media(max-width:375px){
        font-size: 14px;
    }
    span{
        font-size: 28px;
        text-transform:uppercase;
        font-family: 'wensley_regular';
        @media(min-width:2000px){
         font-size:53px;
        }
        @media(max-width:680px){
        font-size: 30px;
        }
        @media(max-width:580px){
        font-size: 18px;
        margin-left: 8px;
        }
        @media(max-width:475px){
        font-size: 16px;
        }
        @media(max-width:400px){
        font-size: 14px;
        }
    }
}
`;


