import React from 'react'

import bg from '../../../assets/images/ContactPage/contact_bg.jpg';
import styled from 'styled-components';

function ContactBanner() {
  return (
    <Section>
    <Container>
        <img loading='lazy'  src={bg} alt="" />
        <Box>
          <Title>
             CONTACT
          </Title>
          <Body>
            Get in touch to design your dream home
          </Body>
        </Box>
    </Container>
  </Section>
  )
}

export default ContactBanner;


const Section = styled.div`
`;

const Container = styled.div`
 height:100%;
 width:100%;
 /* margin-top: 70px; */
 position: relative;
 img{
    width:100%;
    display:block;
    @media(max-width:580px){
    height:250px;
    }
 }
`;

const Box = styled.div`
    position: absolute;
    width: 60%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding:90px 200px;

    @media(min-width:2000px){
      padding:125px 300px;
    }
    @media(max-width:1400px){
      padding:75px 115px;
    }
    @media(max-width:1024px){
      padding:50px 50px;
    }
    @media(max-width:480px){
        padding:15px 25px;
    }
`;

const Title = styled.h1`
    font-family: 'wensley_bold';
    color: #fff;
    width: 100%;
    margin:0;
    padding: 0;
    font-size: 38px;
    margin-bottom:5px;

    @media(min-width:2000px){
      font-size: 50px;
    }

    @media(min-width:580px){
      font-size: 35px;
    }

`;

const Body = styled.p`
    font-family: 'raleway_light';
    color: #fff;
    width: 100%;
    margin:0;
    padding: 0;
    width: 50ch;
    font-size: 14px;
    line-height:16px;

    @media(min-width:2000px){
      width: 60ch;
      font-size: 19px;
      line-height: 22px;
    }

    @media(min-width:580px){
      font-size: 13px;
    }

    @media(max-width:480px){
      width:40ch;
    }
    @media(max-width:375px){
      font-size:12px;
    }
    @media(max-width:324px){
      width:32ch;
    }
`;