import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Header from '../components/includes/Header';
import AboutBanner from '../components/pages/about/AboutBanner';
import styled from 'styled-components';
import TopArea from '../components/pages/about/TopArea';
import AboutContent from '../components/pages/about/AboutContent';
import Footer from '../components/includes/Footer';
import Helmet from '../components/utils/Helmet';
import { ListSingleSeoData } from '../axios/api';


function AboutPage() {
  const [seoData, setseoData] = useState({});
  const path = window.location.pathname
  
  // get seo Data
  useEffect(() => {
    axios.get(ListSingleSeoData,{
      params:{
        links:path
      }
    }).then((response) => {
      const { data, StatusCode } = response.data.app_data;

      if (StatusCode === 6000) {
        setseoData(data[0]);
      } else {
        setseoData({});
      }
    });
  }, []);
  
  return (
    <>
    <Helmet
        titleData={seoData?.meta_title}
        descriptionData={seoData?.meta_description}
      />
     <Header/>
     <AboutBanner/>
     <Section>
       <TopArea/>
       <AboutContent/>
     </Section>
     <Footer/>
    </>
  )
}

export default AboutPage;

const Section = styled.div`
 width:80%;
 margin: 25px auto;
 @media(max-width:1025px){
    width: 90%;
  };
`;