import React from 'react'
import Helmet from '../components/utils/Helmet'
import Header from '../components/includes/Header'
import Footer from '../components/includes/Footer'
import Contents from '../components/pages/termsandconditions/Contents'

function TermsAndConditions() {
  return (
   <>
    <Helmet
        titleData="Terms And Conditions"
        descriptionData="Terms And Conditions"
      />
      <Header />
      <Contents/>
      <Footer />
   </>
  )
}

export default TermsAndConditions